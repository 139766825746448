import { put, call, all, takeLatest, select } from "redux-saga/effects";
import { getFreeSubscriptions } from "../../helpers/getFreeSubscriptions";
import services from "../../services";
import { LOCAL_SIGN_UP_SUCCESS, SIGN_IN_SUCCESS } from "../types/auth";
import { HIDE_MODAL } from "../types/modal";
import {
  UPDATE_EXPERT_INFORMATION,
  UPDATE_EXPERT_INFORMATION_SUCCESS,
  UPDATE_EXPERT_INFORMATION_ERROR,
  GET_USER_INFORMATION,
  GET_USER_INFORMATION_SUCCESS,
  GET_USER_INFORMATION_ERROR,
  UPDATE_CURRENT_USER_INFORMATION,
  UPDATE_CURRENT_USER_INFORMATION_SUCCESS,
  UPDATE_CURRENT_USER_INFORMATION_ERROR,
  GET_USER_SUBSCRIPTIONS,
  GET_USER_SUBSCRIPTIONS_SUCCESS,
  GET_USER_SUBSCRIPTIONS_ERROR,
  GET_USER_FREE_SUBSCRIPTIONS,
  GET_USER_FREE_SUBSCRIPTIONS_SUCCESS,
  GET_USER_FREE_SUBSCRIPTIONS_ERROR,
  GET_USER_GROUPS,
  GET_USER_GROUPS_SUCCESS,
  GET_USER_GROUPS_ERROR,
  UPDATE_CURRENT_USER_GROUPS,
  UPDATE_CURRENT_USER_GROUPS_SUCCESS,
  UPDATE_CURRENT_USER_GROUPS_ERROR,
  CHANGE_USER_EMAIL,
  CHANGE_USER_EMAIL_SUCCESS,
  CHANGE_USER_EMAIL_ERROR,
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_ERROR,
  CHANGE_USER_PHONE_SUCCESS,
  CHANGE_USER_PHONE_ERROR,
  CHANGE_USER_PHONE,
  RESET_CHANGE_USER_PHONE,
  GET_SUBSCRIPTIONS_MANAGER_URL,
  GET_SUBSCRIPTIONS_MANAGER_URL_SUCCESS,
  GET_SUBSCRIPTIONS_MANAGER_URL_ERROR,
  CREATE_NEW_EXPERT,
  CREATE_NEW_EXPERT_ERROR,
  CREATE_NEW_EXPERT_SUCCESS,
  CHANGE_USERNAME,
  CHANGE_USERNAME_ERROR,
  CHANGE_USERNAME_SUCCESS,
  RESET_CHANGE_USERNAME,
  CHANGE_USER_AVATAR,
  CHANGE_USER_AVATAR_SUCCESS,
  CHANGE_USER_AVATAR_ERROR,
  USER_IS_LOGGEDIN,
  CHANGE_TRACKING,
  CHANGE_TRACKING_SUCCESS,
  CHANGE_TRACKING_ERROR,
} from "../types/user";

const getCurrentUserGroups = ({ userStore }) => userStore.groups.data;
const getCurrentUserSubscriptions = ({ userStore }) => userStore.subscriptions.data;

function* getUserInformation() {
  try {
    const { error, response } = yield call(services.user.getUserInformation);
    if (error) {
      yield put({ type: GET_USER_INFORMATION_ERROR, payload: error });
    } else {
      yield all([
        put({ type: GET_USER_INFORMATION_SUCCESS, payload: response }),
        put({ type: USER_IS_LOGGEDIN, payload: true }),
        put({ type: GET_USER_SUBSCRIPTIONS, payload: null }),
      ]);
    }
  } catch (error) {
    yield put({ type: GET_USER_INFORMATION_ERROR, payload: 'Error al obtener datos del usuario' });
  }
}

function* updateCurrentUserInformation() {
  try {
    const { error, response } = yield call(services.user.getUserInformation);
    if (error) {
      yield put({
        type: UPDATE_CURRENT_USER_INFORMATION_ERROR,
        payload: error,
      });
    } else {
      yield put({
        type: UPDATE_CURRENT_USER_INFORMATION_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_CURRENT_USER_INFORMATION_ERROR,
      payload: 'Error al actualizar la información del usuario',
    });
  }
}

function* getUserSubscriptions({ payload }) {
  try {
    //const currentUserSubscriptions = yield select(getCurrentUserSubscriptions);
    const { response, error } = yield call(services.user.getUserSubscriptions, {...payload});

    if (error) {
      yield put({ type: GET_USER_SUBSCRIPTIONS_ERROR, payload: error });
    } else {
      yield put({ type: GET_USER_SUBSCRIPTIONS_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({ type: GET_USER_SUBSCRIPTIONS_ERROR, payload: 'Error al obtener subscripciones.' });
  }
}

function* getUserFreeSubscriptions() {
  try {
    //const currentUserSubscriptions = yield select(getCurrentUserSubscriptions);
    const freeSubscriptions = getFreeSubscriptions()

    yield put({ type: GET_USER_FREE_SUBSCRIPTIONS_SUCCESS, payload: freeSubscriptions });
  } catch (error) {
    yield put({ type: GET_USER_FREE_SUBSCRIPTIONS_ERROR, payload: 'Error al obtener subscripciones gratuitas.' });
  }
}

function* getUserGroups() {
  try {
    const result = yield call(services.user.getUserGroups);
    yield put({ type: GET_USER_GROUPS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_USER_GROUPS_ERROR, payload: error });
  }
}

function* updateCurrentUserGroups({ payload }) {
  try {
    const currentUserGroups = yield select(getCurrentUserGroups);
    yield put({
      type: UPDATE_CURRENT_USER_GROUPS_SUCCESS,
      payload: [...currentUserGroups, { ...payload }],
    });
  } catch (error) {
    yield put({ type: UPDATE_CURRENT_USER_GROUPS_ERROR, payload: error });
  }
}

function* changeUserEmail({ payload }) {
  // try {
  //   yield call(services.user.changeFirebaseEmail, payload);
  //   yield put({ type: CHANGE_USER_EMAIL_SUCCESS });
  // } catch (error) {
  //   yield put({ type: CHANGE_USER_EMAIL_ERROR, payload: error });
  // }

  try {
    const { response, error } = yield call(
      services.user.changeFirebaseEmail,
      payload
    );

    if (error) {
      yield put({ type: CHANGE_USER_EMAIL_ERROR, payload: error?.code ? error.code : error });
    } else {
      yield put({ type: CHANGE_USER_EMAIL_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: CHANGE_USER_EMAIL_ERROR,
      payload: "Error al cambiar el e-mail.",
    });
  }
}

function* changeUsername({ payload }) {
  try {
    const result = yield call(services.expert.updateExpertInformation, payload);
    yield all([
      put({ type: CHANGE_USERNAME_SUCCESS, payload: result }),
      put({ type: UPDATE_CURRENT_USER_INFORMATION, payload: null }),
    ]);
  } catch (error) {
    yield put({ type: CHANGE_USERNAME_ERROR, payload: error });
  }
}

function* changeTracking({ payload }) {
  try {
    const result = yield call(services.expert.updateExpertInformation, payload);
    yield all([
      put({ type: CHANGE_TRACKING_SUCCESS, payload: result }),
      put({ type: UPDATE_CURRENT_USER_INFORMATION, payload: null }),
    ]);
  } catch (error) {
    yield put({ type: CHANGE_TRACKING_ERROR, payload: error });
  }
}

function* changeUserPhone({ payload }) {
  try {
    const result = yield call(services.user.editUserInformation, payload);
    yield all([
      put({ type: CHANGE_USER_PHONE_SUCCESS, payload: result }),
      put({ type: UPDATE_CURRENT_USER_INFORMATION, payload: null }),
    ]);
  } catch (error) {
    yield put({ type: CHANGE_USER_PHONE_ERROR, payload: error });
  }
}

function* changeUserAvatar({ payload }) {
  try {
    const result = yield call(services.expert.updatePicture, payload);
    yield all([
      put({ type: CHANGE_USER_AVATAR_SUCCESS, payload: result }),
      put({ type: UPDATE_CURRENT_USER_INFORMATION, payload: null }),
    ]);
  } catch (error) {
    yield put({ type: CHANGE_USER_AVATAR_ERROR, payload: error });
  }
}

function* changeUserPassword({ payload }) {
  try {
    yield call(services.user.changeFirebasePassword, payload);
    yield put({ type: CHANGE_USER_PASSWORD_SUCCESS });
  } catch (error) {
    yield put({ type: CHANGE_USER_PASSWORD_ERROR, payload: error });
  }
}

function* getSubscriptionsManagerUrl() {
  try {
    const result = yield call(services.user.subscriptionsManagerUrl);
    yield put({ type: GET_SUBSCRIPTIONS_MANAGER_URL_SUCCESS, payload: result });
  } catch (error) {
    yield put({
      type: GET_SUBSCRIPTIONS_MANAGER_URL_ERROR,
      payload: error,
    });
  }
}

function* createNewExpert({ payload }): any {
  try {
    const result = yield call(services.expert.createNewExpert, payload);
    yield all([
      put({ type: CREATE_NEW_EXPERT_SUCCESS, payload: result }),
      put({ type: UPDATE_CURRENT_USER_INFORMATION, payload: null }),
    ]);
  } catch (error) {
    yield put({ type: CREATE_NEW_EXPERT_ERROR, payload: error });
  }
}

function* updateExpertInformation({ payload }): any {
  try {
    const result = yield call(services.expert.updateExpertInformation, payload);
    yield all([
      put({ type: UPDATE_EXPERT_INFORMATION_SUCCESS, payload: result }),
      put({ type: UPDATE_CURRENT_USER_INFORMATION, payload: null }),
      put({ type: HIDE_MODAL, payload: null }),
    ]);
  } catch (error) {
    yield put({
      type: UPDATE_EXPERT_INFORMATION_ERROR,
      payload: error,
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(CREATE_NEW_EXPERT, createNewExpert);
  yield takeLatest<any>(
    GET_SUBSCRIPTIONS_MANAGER_URL,
    getSubscriptionsManagerUrl
  );

  yield takeLatest<any>(CHANGE_USER_EMAIL, changeUserEmail);
  yield takeLatest<any>(CHANGE_USER_EMAIL_SUCCESS, getUserInformation);
  yield takeLatest<any>(CHANGE_USER_PASSWORD, changeUserPassword);

  yield takeLatest<any>(UPDATE_EXPERT_INFORMATION, updateExpertInformation);

  yield takeLatest<any>(GET_USER_INFORMATION, getUserInformation);
  yield takeLatest<any>(
    UPDATE_CURRENT_USER_INFORMATION,
    updateCurrentUserInformation
  );
  //yield takeLatest<any>(GET_USER_INFORMATION_SUCCESS, getUserSubscriptions);
  yield takeLatest<any>(GET_USER_SUBSCRIPTIONS, getUserSubscriptions);
  yield takeLatest<any>(GET_USER_FREE_SUBSCRIPTIONS, getUserFreeSubscriptions);

  yield takeLatest<any>(GET_USER_GROUPS, getUserGroups);
  yield takeLatest<any>(SIGN_IN_SUCCESS, getUserInformation);

  yield takeLatest<any>(CHANGE_USERNAME, changeUsername);
  yield takeLatest<any>(CHANGE_TRACKING, changeTracking);
  yield takeLatest<any>(CHANGE_USER_AVATAR, changeUserAvatar);
  yield takeLatest<any>(UPDATE_CURRENT_USER_GROUPS, updateCurrentUserGroups);

  yield takeLatest<any>(CHANGE_USER_PHONE, changeUserPhone);
}
