import { GetSubscriptionsOutDto } from "../../types";
import {
  GET_USER_INFORMATION,
  GET_USER_SUBSCRIPTIONS,
  GET_USER_GROUPS,
  GET_SUBSCRIPTIONS_MANAGER_URL,
  RESET_USER_INFORMATION,
  CHANGE_USER_EMAIL,
  RESET_CHANGE_USER_EMAIL,
  CHANGE_USER_PHONE,
  RESET_CHANGE_USER_PHONE,
  CHANGE_USER_PASSWORD,
  UPDATE_EXPERT_INFORMATION,
  GetUserInformation,
  GetUserGroups,
  GetUserSubscriptions,
  CREATE_NEW_EXPERT,
  CHANGE_USERNAME,
  RESET_CHANGE_USERNAME,
  CHANGE_USER_AVATAR,
  RESET_CHANGE_USER_AVATAR,
  CHANGE_TRACKING,
  RESET_CHANGE_TRACKING,
  GetUserFreeSubscriptions,
  GET_USER_FREE_SUBSCRIPTIONS
} from "../types/user";

export const getUserInformation = (): GetUserInformation => {
  return { type: GET_USER_INFORMATION, payload: null };
};

export const getUserSubscriptions = (payload?: GetSubscriptionsOutDto): GetUserSubscriptions => {
  return { type: GET_USER_SUBSCRIPTIONS, payload: payload };
};

export const getUserFreeSubscriptions = (): GetUserFreeSubscriptions => {
  return { type: GET_USER_FREE_SUBSCRIPTIONS, payload: null };
};


export const getUserGroups = (): GetUserGroups => {
  return { type: GET_USER_GROUPS, payload: null };
};

export const getSubscriptionsManagerUrl = () => {
  return { type: GET_SUBSCRIPTIONS_MANAGER_URL, payload: null };
};

export const changeUserPhone = (payload: {
  phone_area_code;
  phone_number;
  userId: number;
}) => {
  return { type: CHANGE_USER_PHONE, payload };
};

export const resetchangeUserPhone = () => {
  return { type: RESET_CHANGE_USER_PHONE, payload: null };
};

export const changeUserEmail = (data: { email: string; userId: number }) => {
  return { type: CHANGE_USER_EMAIL, payload: data };
};

export const resetChangeUserEmail = () => {
  return { type: RESET_CHANGE_USER_EMAIL, payload: null };
};

export const changeUsername = (username: string) => {
  return { type: CHANGE_USERNAME, payload: username };
};

export const resetChangeUsername = () => {
  return { type: RESET_CHANGE_USERNAME, payload: null };
};

export const changeUserAvatar = (payload) => {
  return { type: CHANGE_USER_AVATAR, payload };
};

export const resetChangeUserAvatar = () => {
  return { type: RESET_CHANGE_USER_AVATAR, payload: null };
};

export const changeTracking = (payload) => {
  return { type: CHANGE_TRACKING, payload };
};

export const resetChangeTracking = () => {
  return { type: RESET_CHANGE_TRACKING, payload: null };
};

export const changeUserPassword = (email: string) => {
  return { type: CHANGE_USER_PASSWORD, payload: email };
};

export const resetUserStore = () => {
  return { type: RESET_USER_INFORMATION, payload: null };
};

export const createNewExpert = (payload) => {
  return { type: CREATE_NEW_EXPERT, payload };
};

export const updateExpertInformation = (payload) => {
  return { type: UPDATE_EXPERT_INFORMATION, payload };
};
