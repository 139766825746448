import {
  Switch,
  Route,
  useLocation,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import Community from "./Community";
import Membership from "./Membership";
import Experience from "./Experience";
import Members from "./Community/Members";
import Coupon from "./Coupon";
import Configuration from "./Configuration";

import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getUserGroups } from "../../store/actions/user";
import forward from "../../helpers/fowardUrl";
import { Expert } from "../../types";
import DashboardLayout from "../../components/Layout/DashboardLayout";

const Component = ({ userInformation }) => {
  const [expert, setExpert] = useState<Expert>();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserGroups());
    forward.goFoward();
  }, []);

  useEffect(() => {
    if (!!userInformation?.expert) {
      setExpert(userInformation?.expert);
    }
  }, [userInformation]);

  return (
    <Switch>
      <Route
        exact
        path={`${path}/`}
        render={() => <Redirect to={`${path}/community`} />}
      />
      {/* <Route
        path={`${path}/home`}
        render={() => (
          <DashboardLayout useLocation={useLocation}>
            <Home />
          </DashboardLayout>
        )}
      /> */}
      {/* <Route
        path={`${path}/diffusion`}
        render={() => (
          <SimpleLayout useLocation={useLocation} showMenu={false}>
            <Diffusion />
          </SimpleLayout>
        )}
      /> */}
      <Route path={`${path}/coupon`} component={Coupon} />
      <Route path={`${path}/community`} component={Community} />
      <Route path={`${path}/membership`} component={Membership} />
      <Route path={`${path}/experience`} component={Experience} />
      <Route path={`${path}/members`} component={Members} />

      <Route exact path={`${path}/*`} render={() => <Redirect to={"/404"} />} />
    </Switch>
  );
};

const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;

  return {
    userInformation,
  };
};

export default connect(state)(Component);
