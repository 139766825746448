
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";

import RainbowLayout from "../../components/Layout/RainbowLayout";

import Success from "./Success";
import Error from "./Error";
import FreeSubscription from "./FreeSubscription";
import PaidSubscription from "./PaidSubscription";
import ChangePagymentMethod from "./ChangePagymentMethod";

const Componet = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/free-subscription/:idGroup`}
        render={(props) => <FreeSubscription {...props} />}
      />
      <Route path={`${path}/success`} render={(props) => <Success  {...props} />} />
      <Route path={`${path}/faliure`} render={() => <RainbowLayout useLocation={useLocation}><Error /></RainbowLayout>} />
      <Route path={`${path}/payment`} render={() => <RainbowLayout useLocation={useLocation}><PaidSubscription /></RainbowLayout>} />
      <Route path={`${path}/change-payment-method`} render={() => <RainbowLayout useLocation={useLocation}><ChangePagymentMethod /></RainbowLayout>} />
    </Switch>
  );
};

export default Componet;
