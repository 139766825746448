import {
  initialState,
  Actions,
  State,
  USER_IS_LOGGEDIN,
  UPDATE_EXPERT_INFORMATION,
  UPDATE_EXPERT_INFORMATION_SUCCESS,
  UPDATE_EXPERT_INFORMATION_ERROR,
  GET_USER_INFORMATION,
  GET_USER_INFORMATION_SUCCESS,
  GET_USER_INFORMATION_ERROR,
  UPDATE_CURRENT_USER_INFORMATION,
  UPDATE_CURRENT_USER_INFORMATION_SUCCESS,
  UPDATE_CURRENT_USER_INFORMATION_ERROR,
  GET_USER_SUBSCRIPTIONS,
  GET_USER_SUBSCRIPTIONS_SUCCESS,
  GET_USER_SUBSCRIPTIONS_ERROR,

  GET_USER_FREE_SUBSCRIPTIONS,
  GET_USER_FREE_SUBSCRIPTIONS_SUCCESS,
  GET_USER_FREE_SUBSCRIPTIONS_ERROR,

  GET_USER_GROUPS,
  GET_USER_GROUPS_SUCCESS,
  GET_USER_GROUPS_ERROR,
  UPDATE_CURRENT_USER_GROUPS,
  UPDATE_CURRENT_USER_GROUPS_SUCCESS,
  UPDATE_CURRENT_USER_GROUPS_ERROR,
  RESET_USER_INFORMATION,
  RESET_CHANGE_USER_EMAIL,
  CHANGE_USER_EMAIL,
  CHANGE_USER_EMAIL_SUCCESS,
  CHANGE_USER_EMAIL_ERROR,
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_ERROR,
  GET_SUBSCRIPTIONS_MANAGER_URL,
  GET_SUBSCRIPTIONS_MANAGER_URL_SUCCESS,
  GET_SUBSCRIPTIONS_MANAGER_URL_ERROR,
  CREATE_NEW_EXPERT,
  CREATE_NEW_EXPERT_SUCCESS,
  CREATE_NEW_EXPERT_ERROR,
  CHANGE_USERNAME,
  CHANGE_USERNAME_SUCCESS,
  CHANGE_USERNAME_ERROR,
  RESET_CHANGE_USERNAME,
  CHANGE_USER_PHONE,
  CHANGE_USER_PHONE_SUCCESS,
  CHANGE_USER_PHONE_ERROR,
  RESET_CHANGE_USER_PHONE,
  CHANGE_TRACKING,
  CHANGE_TRACKING_SUCCESS,
  CHANGE_TRACKING_ERROR,
  RESET_CHANGE_TRACKING,
} from "../types/user";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case USER_IS_LOGGEDIN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case GET_USER_INFORMATION:
      return {
        ...state,
        information: {
          data: state.information.data,
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case GET_USER_INFORMATION_SUCCESS:
      return {
        ...state,
        information: {
          data: action.payload,
          states: {
            success: true,
            loading: false,
            error: false,
          },
        },
      };
    case GET_USER_INFORMATION_ERROR:
      return {
        ...state,
        information: {
          data: null,
          states: {
            success: false,
            loading: false,
            error: action.payload,
          },
        },
      };
    case UPDATE_CURRENT_USER_INFORMATION:
      return {
        ...state,
        information: {
          data: state.information.data,
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case UPDATE_CURRENT_USER_INFORMATION_SUCCESS:
      return {
        ...state,
        information: {
          data: action.payload,
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case UPDATE_CURRENT_USER_INFORMATION_ERROR:
      return {
        ...state,
        information: {
          data: state.information.data,
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case GET_USER_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: {
          data: state.subscriptions.data,
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case GET_USER_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: {
          data: action.payload,
          states: {
            success: true,
            loading: false,
            error: false,
          },
        },
      };
    case GET_USER_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        subscriptions: {
          data: null,
          states: {
            success: false,
            loading: false,
            error: action.payload,
          },
        },
      };
    case GET_USER_FREE_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: {
          data: state.subscriptions.data,
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case GET_USER_FREE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: {
          data: action.payload,
          states: {
            success: true,
            loading: false,
            error: false,
          },
        },
      };
    case GET_USER_FREE_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        subscriptions: {
          data: null,
          states: {
            success: false,
            loading: false,
            error: action.payload,
          },
        },
      };
    case GET_USER_GROUPS:
      return {
        ...state,
        groups: {
          data: null,
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case GET_USER_GROUPS_SUCCESS:
      return {
        ...state,
        groups: {
          data: action.payload,
          states: {
            success: true,
            loading: false,
            error: false,
          },
        },
      };
    case GET_USER_GROUPS_ERROR:
      return {
        ...state,
        groups: {
          data: null,
          states: {
            success: false,
            loading: false,
            error: action.payload,
          },
        },
      };

    case UPDATE_CURRENT_USER_GROUPS:
      return {
        ...state,
        groups: {
          data: state.groups.data,
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case UPDATE_CURRENT_USER_GROUPS_SUCCESS:
      return {
        ...state,
        groups: {
          data: action.payload,
          states: {
            success: true,
            loading: false,
            error: false,
          },
        },
      };
    case UPDATE_CURRENT_USER_GROUPS_ERROR:
      return {
        ...state,
        groups: {
          data: state.groups.data,
          states: {
            success: false,
            loading: false,
            error: action.payload,
          },
        },
      };

    case CHANGE_USER_EMAIL:
      return {
        ...state,
        changeUserEmail: {
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case CHANGE_USER_EMAIL_SUCCESS:
      return {
        ...state,
        changeUserEmail: {
          states: {
            success: true,
            loading: false,
            error: false,
          },
        },
      };
    case CHANGE_USER_EMAIL_ERROR:
      return {
        ...state,
        changeUserEmail: {
          states: {
            success: false,
            loading: false,
            error: action.payload,
          },
        },
      };
    case CHANGE_USER_PHONE:
      return {
        ...state,
        changeUserPhone: {
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case CHANGE_USER_PHONE_SUCCESS:
      return {
        ...state,
        changeUserPhone: {
          states: {
            success: true,
            loading: false,
            error: false,
          },
        },
      };
    case CHANGE_USER_PHONE_ERROR:
      return {
        ...state,
        changeUserPhone: {
          states: {
            success: false,
            loading: false,
            error: action.payload,
          },
        },
      };
    case RESET_CHANGE_USER_PHONE:
      return {
        ...state,
        changeUserPhone: initialState.changeUserPhone,
      };
    case CHANGE_USER_PASSWORD:
      return {
        ...state,
        changeUserPassword: {
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        changeUserPassword: {
          states: {
            success: true,
            loading: false,
            error: false,
          },
        },
      };
    case CHANGE_USER_PASSWORD_ERROR:
      return {
        ...state,
        changeUserPassword: {
          states: {
            success: false,
            loading: false,
            error: true,
          },
        },
      };
    case GET_SUBSCRIPTIONS_MANAGER_URL:
      return {
        ...state,
        subscruptionsManagerUrl: {
          data: null,
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case GET_SUBSCRIPTIONS_MANAGER_URL_SUCCESS:
      return {
        ...state,
        subscruptionsManagerUrl: {
          data: action.payload,
          states: {
            success: true,
            loading: false,
            error: false,
          },
        },
      };
    case GET_SUBSCRIPTIONS_MANAGER_URL_ERROR:
      return {
        ...state,
        subscruptionsManagerUrl: {
          data: null,
          states: {
            success: false,
            loading: false,
            error: action.payload,
          },
        },
      };
    case CREATE_NEW_EXPERT:
      return {
        ...state,
        createNewExpert: {
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case CREATE_NEW_EXPERT_SUCCESS:
      return {
        ...state,
        createNewExpert: {
          states: {
            success: true,
            loading: false,
            error: false,
          },
        },
      };
    case CREATE_NEW_EXPERT_ERROR:
      return {
        ...state,
        createNewExpert: {
          states: {
            success: false,
            loading: false,
            error: action.payload,
          },
        },
      };
    case CHANGE_USERNAME:
      return {
        ...state,
        changeUsername: {
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case CHANGE_USERNAME_SUCCESS:
      return {
        ...state,
        changeUsername: {
          states: {
            success: true,
            loading: false,
            error: false,
          },
        },
      };
    case CHANGE_USERNAME_ERROR:
      return {
        ...state,
        changeUsername: {
          states: {
            success: false,
            loading: false,
            error: action.payload,
          },
        },
      };
    case RESET_CHANGE_USERNAME:
      return {
        ...state,
        changeUsername: {
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };

    case CHANGE_TRACKING:
      return {
        ...state,
        changeTracking: {
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case CHANGE_TRACKING_SUCCESS:
      return {
        ...state,
        changeTracking: {
          states: {
            success: true,
            loading: false,
            error: false,
          },
        },
      };
    case CHANGE_TRACKING_ERROR:
      return {
        ...state,
        changeTracking: {
          states: {
            success: false,
            loading: false,
            error: action.payload,
          },
        },
      };
    case RESET_CHANGE_TRACKING:
      return {
        ...state,
        changeTracking: {
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };

    case UPDATE_EXPERT_INFORMATION:
      return {
        ...state,
        updateExpertInformation: {
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case UPDATE_EXPERT_INFORMATION_SUCCESS:
      return {
        ...state,
        updateExpertInformation: {
          states: {
            success: true,
            loading: false,
            error: false,
          },
        },
      };
    case UPDATE_EXPERT_INFORMATION_ERROR:
      return {
        ...state,
        updateExpertInformation: {
          states: {
            success: false,
            loading: false,
            error: action.payload,
          },
        },
      };
    case RESET_CHANGE_USER_EMAIL:
      return {
        ...state,
        changeUserEmail: {
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case RESET_USER_INFORMATION:
      return initialState;

    default:
      return state;
  }
};

export default reducers;
