import CoverPic from '../CoverPic';

import {
  Title, Column, GroupName, Line, Discount, Total, Currency, FooterMessage, Price
} from "./styles";
import dateHandlder from "../../../../../helpers/dates-hanlder";
import { connect } from "react-redux";
import { formatPrice } from '../../../../../helpers/price-formatter';
import { useEffect, useMemo, useState } from 'react';
import { getUrlParamByName } from '../../../../../helpers/get-url-param';
import { DiscountDto, PlanDto } from '../../../../../types';
import constants from '../../../../../constants';

const groupTypes = {
  community: "comunidad",
  free_experience: "taller",
  experience: "taller",
  membership: "membresía",
};


const periodsUnitsTranslations = {
  "month": { singular: "mes", plural: "meses" },
  "quarter": { singular: "trimestre", plural: "trimestres" },
  "half-year": { singular: "semestre", plural: "semestres" },
  "year": { singular: "año", plural: "años" },
}


const Component = ({ group }) => {
  const [plan, setPlan] = useState<PlanDto>();
  const [discount, setDiscount] = useState<DiscountDto>();
  const [secondaryCurrency, setSecondaryCurrency] = useState<string | null>('');
  const [suscriptionEnded, setSuscriptionEnded] = useState<boolean>();

  const planId = useMemo(() => Number(getUrlParamByName('planId')), [])

  const paymentDiscountTranslated = (plan?: PlanDto) => {
    const discount = plan?.["discounts"][0];

    if (discount) {
      const periodSelected = discount["period"] / (plan?.["period"] || 1); //Cantidad de cobros
      return `${periodSelected > 1 ? `Los primeros ${periodSelected} pagos` : 'Pago'} con descuento.`
    } else {
      return ""
    }
  }

  const paymentPeriodTranslated = (plan?: PlanDto) => {
    if (plan?.["period_unit"] && plan?.["period"]) {
      const periodSelected = plan?.["period"]; //Cantidad de cobros
      const selectedPeriodUnit = periodsUnitsTranslations[plan?.["period_unit"]] //Meses / Trimestres / Años / Etc...
      return `Pagas ${plan?.["period"] <= 1 ? "por" : `cada ${plan?.["period"]}`} ${selectedPeriodUnit[periodSelected > 1 ? "plural" : "singular"]}.`
    } else {
      return ""
    }
  }


  const explainText = (plan?: PlanDto) => {
    const paymentCicles = plan?.['billing_cycles'] ? `${plan?.['billing_cycles']} cobros en total.` : "";
    const paymentPeriod = plan?.["type"] === "charge" ? "Pagá 1 sola vez." : paymentPeriodTranslated(plan);
    const paymentDiscount = paymentDiscountTranslated(plan);

    return `${paymentCicles} ${paymentPeriod} ${paymentDiscount}`;
  }

  const planPeriod = (plan?: PlanDto) => {
    if (plan) {
      if (plan.type === 'subscription') {
        const isPlural = plan?.period && plan?.period > 1;
        const period = plan?.period_unit && periodsUnitsTranslations[plan?.period_unit]
        return `Cada ${plan?.period} ${period[isPlural ? "plural" : "singular"]}`
      } else {
        return "Pago único"
      }
    }
  }

  useEffect(() => {
    if (!!group && !!planId) {
      const selectedPlan = group?.plans.find((item) => item.id === planId);
      const selectedPlanDiscount = selectedPlan?.discounts[0];
      const selectedPlanSecondaryCurrency = !!selectedPlan?.secondary_currency_rate ? constants.currencies.filter(c => c.iso === selectedPlan?.showable_secondary_currency)[0]?.iso : null
      setDiscount(selectedPlanDiscount)
      setSecondaryCurrency(selectedPlanSecondaryCurrency)
      setPlan(selectedPlan);
    }

    if (!!group?.registration_closing_date) {
      setSuscriptionEnded(
        dateHandlder.isExpired(group?.registration_closing_date)
      );
    }
  }, [group, planId]);

  return (
    <Column>
      <div style={{ width: '100%', maxWidth: 350 }}>
        <Title>Resumen del pedido</Title>
        <div style={{ height: 20 }} />
        <CoverPic />
        <div style={{ height: 20 }} />
        <GroupName>{group?.group_name}</GroupName>
        <div style={{ height: 20 }} />
        <Line />
        <div style={{ height: 12 }} />
        <Price>Precio <span>$ {formatPrice(plan?.price)}</span>
        </Price>
        <div style={{ height: 14 }} />
        <Discount>Descuento promocional <span>- ${formatPrice(plan?.discounts[0]?.amount || 0)}</span></Discount>
        <div style={{ height: 12 }} />
        <Line />
        <div style={{ height: 20 }} />
        <Total>Total <span>${!!discount
          ? formatPrice((plan?.price || 0) - (plan?.discounts[0]?.amount || 0))
          : formatPrice(plan?.price)}<small> /{planPeriod(plan)}</small></span></Total>
        <div style={{ height: 20 }} />
        <Currency>Monto total a pagar en {plan?.currency}.</Currency>
        <div style={{ height: 10 }} />
        <FooterMessage>{explainText(plan)} Los cobros se harán por débito automático a tu tarjeta y puedes cancelar en cualquier momento.</FooterMessage>
      </div>
    </Column>
  )
}

const state = ({ groupStore }) => {
  const { data: group } = groupStore?.group;

  return { group };
};

export default connect(state)(Component);