import Grid from '../../../../Grid';
import LogoutMenu from "./LogoutMenu";
import LoginMenu from "./LoginMenu";
import { Header, Back, Type } from "./styles";

import { connect } from "react-redux";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import { User } from "../../../../../types";
import backArrow from "../../../../../assets/icons/arrow-backward.svg";
import Icon from "../../../../Icon";
import { Center } from "../../../../StyledComponents";
import { useLayout } from "../../../../../providers/LayoutProvider";

interface ComponentProps {
  user: User;
}

const Component = ({
  user
}: ComponentProps) => {
  const { layout } = useLayout();
  const { isMobile } = useWindowSize();

  //console.log({ previousPage }, document.referrer)

  // const isOwnPost = useMemo(() => post?.group?.expert?.id === user?.expert?.id, [post, user])
  // const isOwnSession = useMemo(() => session?.group?.expert?.id === user?.expert?.id, [session, user])
  // const isOwnGroup = useMemo(() => category?.group?.expert === user?.expert?.id, [category, user])

  // useEffect(() => {
  //   console.log("location", location)
  // }, [location])

  // useEffect(() => {
  //   console.log("history", history)
  // }, [history])

  // const backUrl = useMemo(() => {
  //   if (!!post) {
  //     if (isOwnPost) {
  //       if (previousPage) {
  //         return previousPage;
  //       } else {
  //         return `/group/${post?.group?.slug}/library/`
  //       }
  //       // if( post?.group?.group_type === "free_experience") {
  //       //   return `/dashboard/community/events/${post?.group?.id}/posts`
  //       // }else{
  //       //   return `/dashboard/${post?.group?.group_type}/${post?.group?.id}`
  //       // }
  //     } else {
  //       return `/group/${post?.group?.slug}/library/`
  //     }
  //   } else if (!!session) {
  //     if (isOwnSession) {
  //       if (previousPage) {
  //         return previousPage;
  //       } else {
  //         return `/group/${session?.group?.slug}/sessions/`
  //       }
  //       // if( post?.group?.group_type === "free_experience") {
  //       //   return `/dashboard/community/events/${post?.group?.id}/posts`
  //       // }else{
  //       //   return `/dashboard/${post?.group?.group_type}/${post?.group?.id}`
  //       // }
  //     } else {
  //       return `/group/${session?.group?.slug}/sessions/`
  //     }
  //   } else if (!!category) {
  //     if (isOwnGroup) {
  //       if (previousPage) {
  //         return previousPage;
  //       } else {
  //         return `/group/${category?.group?.slug}/library`
  //       }
  //     } else {
  //       return `/group/${category?.group?.slug}/library/`
  //     }
  //   } else {
  //     return ''
  //   }
  // }, [isOwnPost, isOwnSession, post, session, category])

  return (
    <Header>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col lg={10} offset={{ lg: 1 }}>
            <Center horizontal="center" vertical="center">

              {layout?.page?.showBack && (
                <Back href={layout?.page?.back}>
                  <Icon icon={backArrow} color="black" size="24px" />
                  {!isMobile && "Volver"}
                </Back>
              )}

              <Type>{layout?.page?.title || ""}</Type>

              {!!user ? <LoginMenu /> : <LogoutMenu />}
            </Center >
          </Grid.Col >
        </Grid.Row >
      </Grid.Container >
    </Header >
  );
};

const state = ({ userStore }) => {

  const { data: user } = userStore.information;


  return {
    user,
  };
};

export default connect(state)(Component);
