import Modal from "../../../../../../../components/Modal";
import Button from "../../../../../../../components/Button";
import {
  Title,
  LoaderWrapper,
  LoaderBackground
} from "./styles";
import { connect, useDispatch } from "react-redux";
import Snackbar from '../../../../../../../components/Snackbar'
import { initialValues, schema } from '../../../../../../../constants/forms/welcome-section/edit'
import Submit from "../../../../../../../components/FormikForm/Submit";
import { hideModal } from "../../../../../../../store/actions/modal";
import Form from '../Form'
import { CategoryDto } from "../../../../../../../types/category";
import { Center } from "../../../../../../../components/StyledComponents";
import { useEffect, useState } from "react";
import { editWelcomeContentSection, resetEditWelcomeContentSection } from "../../../../../../../store/actions/welcome-content";
import { Formik } from "formik";
import { WelcomeContentSectionDto } from "../../../../../../../types/welcome-section";
import { Group } from "../../../../../../../types";

interface ComponentProps {
  editedWelcomeContentSectionStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  welcomeContentSection,
}

const Component = ({
  editedWelcomeContentSectionStates,
  welcomeContentSection,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [actions, setActions] = useState<{ resetForm: () => void }>()
  const [formSubmmited, setFormSubmmited] = useState(false);

  const onSubmit = ({ values, actions }) => {
    setActions(actions)
    const payload = {
      title: values?.title,
      description: values?.description
    }
    dispatch(editWelcomeContentSection({ idWelcomeContentSection: welcomeContentSection?.id, body: payload }));
  };

  const handleClose = () => {
    dispatch(hideModal())
  }

  useEffect(() => {
    if (editedWelcomeContentSectionStates.success) {
      handleClose()

      actions?.resetForm()

      setTimeout(() => {
        dispatch(resetEditWelcomeContentSection());
      }, 2000);
    }
  }, [editedWelcomeContentSectionStates]);

  return (
    <>
      <Modal name="modal-edit-welcome-section" width="650" onClose={() => { }}>
        <Title>Bienvenida para nuevos suscriptores</Title>

        <div style={{ height: 22 }} />

        <Formik
          initialValues={{ ...initialValues, ...welcomeContentSection }}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnChange={false}
          validateOnBlur={formSubmmited}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="edit-welcome-section"
              >
                <Form
                  touched={touched}
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </form>
            )
          }}
        </Formik>


        <Center horizontal="right" vertical="center" gap={10}>
          <Button
            disabled={editedWelcomeContentSectionStates.loading}
            options={{
              type: "outline",
              size: "lg",
              skin: "purple",
            }}
            onClick={handleClose}
          >
            Cancelar
          </Button>

          <Submit
            isSubmmiting={editedWelcomeContentSectionStates.loading}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            form="edit-welcome-section"
          >
            Guardar cambios
          </Submit>
        </Center>
      </Modal>

      <Snackbar
        visible={(editedWelcomeContentSectionStates.success || !!editedWelcomeContentSectionStates.error || editedWelcomeContentSectionStates.loading)}
        options={{
          time: 500,
          type: editedWelcomeContentSectionStates.success ? "success" : (editedWelcomeContentSectionStates.loading ? "info" : "error"),
        }}
        onHide={() => dispatch(resetEditWelcomeContentSection())}
      >

        {editedWelcomeContentSectionStates.success && "Información Editada exitosamente!"}
        {editedWelcomeContentSectionStates.error && "Error al Editar la seccion."}
      </Snackbar>
    </>
  );
};

const state = ({ groupStore, welcomeContentStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: editedWelcomeContentSection, states: editedWelcomeContentSectionStates } = welcomeContentStore.editWelcomeContentSection;
  const { data: welcomeContentSection, states: welcomeContentSectionStates } = welcomeContentStore.welcomeContentSection;

  return {
    group,
    groupStates,
    editedWelcomeContentSection,
    editedWelcomeContentSectionStates,
    welcomeContentSection,
    welcomeContentSectionStates
  };
};

export default connect(state)(Component);
