import {
  Title, Column, GroupName, Line, Discount, Total, Currency, FooterMessage, Price
} from "./styles";
import { PlanDto } from '../../../../../types';
import { connect, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getGroupById } from '../../../../../store/actions/group';

const periodsUnitsTranslations = {
  "month": { singular: "mes", plural: "meses" },
  "quarter": { singular: "trimestre", plural: "trimestres" },
  "half-year": { singular: "semestre", plural: "semestres" },
  "year": { singular: "año", plural: "años" },
}

const Component = ({ subscription, group, groupStates }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!subscription) {
      console.log(subscription)
      console.log(subscription?.plan?.experts_group)
      dispatch(getGroupById({ group_id: subscription?.plan?.experts_group }));
    }
  }, [subscription]);

  const paymentPeriodTranslated = (plan?: PlanDto) => {
    if (plan?.["period_unit"] && plan?.["period"]) {
      const periodSelected = plan?.["period"]; //Cantidad de cobros
      const selectedPeriodUnit = periodsUnitsTranslations[plan?.["period_unit"]] //Meses / Trimestres / Años / Etc...
      return `Pagas ${plan?.["period"] <= 1 ? "por" : `cada ${plan?.["period"]}`} ${selectedPeriodUnit[periodSelected > 1 ? "plural" : "singular"]}.`
    } else {
      return ""
    }
  }

  const planPeriod = (plan?: PlanDto) => {
    if (plan) {
      if (plan.type === 'subscription') {
        const isPlural = plan?.period && plan?.period > 1;
        const period = plan?.period_unit && periodsUnitsTranslations[plan?.period_unit]
        return `Cada ${plan?.period} ${period[isPlural ? "plural" : "singular"]}`
      } else {
        return "Pago único"
      }
    }
  }

  return (
    <Column>
      <div style={{ width: '100%', maxWidth: 350 }}>
        <Title>Detalles de la suscripción</Title>
        <div style={{ height: 20 }} />
        <GroupName>{group?.group_name}</GroupName>
        <div style={{ height: 20 }} />
        <Line />
        <div style={{ height: 12 }} />
        <Price>Moneda de cobro <span>{subscription?.plan?.currency}</span></Price>
        <div style={{ height: 12 }} />
        <Price>Frecuencia de cobro <span>{planPeriod(subscription?.plan)}</span></Price>
        <div style={{ height: 14 }} />
        <Price>Proxima fecha de cobro <span>{subscription?.next_billing_at}</span></Price>
        <div style={{ height: 14 }} />
        <Line />
        <div style={{ height: 10 }} />
        <FooterMessage>Actualizar tu método de pago no generá ningun cargo adicional. El pago recien se realizará en tu Proxima fecha de cobro habitual mediante débito automático a la nueva tarjeta que ingreses. Ten en cuenta que puedes cancelar tu suscripción en cualquier momento.</FooterMessage>
      </div>
    </Column>
  )
}

const state = ({ subscriptionAdminStore, groupStore }) => {
  const { data: subscription } = subscriptionAdminStore?.subscriptionAdmin;
  const { data: group, states: groupStates } = groupStore.group;



  return { subscription, group, groupStates };
};

export default connect(state)(Component);