import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Modal from "../../../../../components/Modal";
import { Title, Text, MessageContainer, Dots } from "./styles";
import { Center } from "../../../../../components/StyledComponents";
import remove from "../../../../../assets/remove.svg";
import spinner from "../../../../../assets/spinnerloader.svg";
import Icon from "../../../../../components/Icon";

interface ComponentProps {
  visibleModal: string
}

const Component = ({ visibleModal }: ComponentProps) => {
  // Estado para manejar la frase actual
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);

  const isOpen = useMemo(() => { return visibleModal === "modal-process-new-subscription" }, [visibleModal])

  // Frases que se mostrarán en el modal
  const messages = [
    'Creando un entorno seguro para la suscripción',
    'Validando tu información de pago',
    'Completando el pago',
  ];

  useEffect(() => {
    if (isOpen && currentMessageIndex < messages.length) {
      const interval = setInterval(() => {
        if (currentMessageIndex < messages.length - 1)
          setShowSuccess(true);

        // Mostrar el icono de éxito por 1 segundo antes de avanzar al siguiente mensaje
        setTimeout(() => {
          setShowSuccess(false);

          setCurrentMessageIndex((prevIndex) => {
            // Si estamos en el último mensaje, detenemos la animación
            if (prevIndex === messages.length - 1) {
              clearInterval(interval);
              return prevIndex;
            }
            return prevIndex + 1;
          });
        }, 1000);
      }, 3000); // Cambia la frase cada 3 segundos

      // Limpieza del temporizador al desmontar el componente
      return () => clearInterval(interval);
    }
  }, [isOpen, currentMessageIndex, messages.length]);

  // Resetea los mensajes si el modal se cierra
  useEffect(() => {
    if (!isOpen) {
      setCurrentMessageIndex(0);
      setShowSuccess(false);
    }
  }, [isOpen]);

  return (
    <Modal name="modal-process-new-subscription" width="659" showClose={false}>
      <Center horizontal="center" vertical="center" gap={8} column={true}>
        <Icon icon={spinner} color={"gray"} size="40px" />
        <Title>Aguarda mientras procesamos tu pago</Title>
        <Text>
          <MessageContainer>
            {messages[currentMessageIndex]}
            {!showSuccess && (<Dots />)}

            <div style={{ marginLeft: "5px" }}>
              {showSuccess && <Icon icon={remove} color="green" size={"24px"} />}
            </div>
          </MessageContainer>
        </Text>
      </Center>
    </Modal>
  );
};

const state = ({ modalStore }) => {
  const { visibleModal } = modalStore

  return { visibleModal }
};


export default connect(state)(Component);