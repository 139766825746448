import { Column, Title, Message } from "./styles";
import Button from '../../../../../components/Button';
import cross from "../../../../../assets/cross.svg";
import Icon from "../../../../../components/Icon"

const Component = ({message}) => {

  return (
    <Column>
      <div style={{borderRadius: "100px", border: "1px solid red", padding: "10px"}}>
      <Icon size={"30px"} icon={cross} color={"red"}></Icon>
      </div>
      <div style={{ height: 20 }} />
      <Title>No puedes actualizar el método de pago de esta suscripción</Title>
      <div style={{ height: 20 }} />
      <Message>{message}</Message>
      <div style={{ height: 35 }} />
      <Button options={{ type: 'filled', size: 'lg', skin: 'purple', block: true }} onClick={() => window.location.href = "/profile/manage-subscriptions"}>Volver</Button>
      <div style={{ height: 15 }} />
      <Button options={{ type: 'outline', size: 'lg', skin: 'purple', block: true }} onClick={() => window.location.reload()}>Contactarme con soporte</Button>
    </Column>
  )
}


export default Component;