import { Column, Title, Message, ProfileImage } from "./styles";
import { connect } from "react-redux";
import Button from '../../../../../components/Button';
import userAvatar from "../../../../../assets/avatar.svg";
import { useMemo } from 'react';

const Component = ({ group }) => {
  const profilePicture = useMemo(() => {
    return group?.expert?.profile_pic?.url || userAvatar
  }, [group])

  return (
    <Column>
      <ProfileImage loading="lazy" src={profilePicture} alt={"Profile Image"} />
      <div style={{ height: 35 }} />
      <Title>El pago fue rechazado</Title>
      <div style={{ height: 20 }} />
      <Message>Al intentar procesar el pago hubo un error proveniente de tu tarjeta o de tu banco. Por favor revisa que los datos sean correctos o vuelve a intentarlo con otra tarjeta.</Message>
      <div style={{ height: 35 }} />
      <Button options={{ type: 'filled', size: 'lg', skin: 'purple', block: true }} onClick={() => window.location.reload()}>Reintentar el pago</Button>
      <div style={{ height: 15 }} />
      <Button options={{ type: 'outline', size: 'lg', skin: 'purple', block: true }} onClick={() => window.location.reload()}>Contactarme con soporte</Button>
    </Column>
  )
}

const state = ({ groupStore }) => {
  const { data: group } = groupStore?.group;

  return { group };
};

export default connect(state)(Component);