import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Layout, useLayout } from '../../../providers/LayoutProvider';
import { Subscription } from '../../../types';
import Success from './components/Success';
import ErrorPaymentMethodUpdate from './components/ErrorPaymentMethodUpdate';
import InvalidSubscription from './components/InvalidSubscription';
import { getUrlParamByName } from '../../../helpers/get-url-param';
import MercadoPagoForm from '../../Checkout/components/MercadoPagoForm'
import { CheckoutContainer, Column } from './styles'
import { getSubscription } from "../../../store/actions/subscription-admin";
import SubscriptionDetails from './components/SubscriptionDetails';
import action from "../../../helpers/rest-client";

interface ComponentProps {
  isLoggedIn: boolean
  subscription: Subscription | null;
  subscriptionStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  isLoggedIn,
  subscription,
  subscriptionStates
}: ComponentProps) => {
  const dispatch = useDispatch();
  const { setLayout, layout } = useLayout();

  const [gatewayError, setGatewayError] = useState(false)
  const [appError, setAppError] = useState(false)
  const [success, setSuccess] = useState(false)

  const onSuccess = (subscription) => {
    setSuccess(true)
    setGatewayError(false)
    setAppError(false)
  }

  const onError = (error) => {
    console.log(error?.response?.data)
    if (error?.response?.data?.source === 'gateway') {
      setGatewayError(true)
    } else if (error?.response?.data?.source === 'application') {
      setAppError(true)
    } else {
      setAppError(true)
    }
    setSuccess(false)
  }

  const subscriptionId = useMemo(() => getUrlParamByName('subscriptionId'), [])

  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "rainbow",
      footer: { visible: false },
      header: { visible: false },
      toolbar: { visible: false },
      body: {
        visible: true,
        background: { color: 'white' },
      },
    }),
    []
  );

  const onSubmit = useCallback(async (cardData) => {
    const payload = {
      payment_details: cardData
    }

    action.Put({
      url: `/api/subscription/${subscriptionId}/payment/update`,
      body: payload,
    }).then((response: Subscription) => {
      onSuccess(response)
    }).catch((error) => {
      onError(error)
    });
  }, []);

  useEffect(() => {
    if (!!subscriptionId) {
      dispatch(getSubscription(Number(subscriptionId)));
    }
  }, [subscriptionId]);

  useEffect(() => {
    setLayout(layoutData);
  }, []);


  if (!isLoggedIn || subscriptionStates.loading) {
    return <></>
  }

  if (!subscriptionId) {
    window.location.href = "/profile/manage-subscriptions"
  }

  if (!!subscriptionStates.error) {
    return <CheckoutContainer><InvalidSubscription message={"Oops! No tienes permisos para actualizar esta suscripción"} /></CheckoutContainer>
  }

  if(!!subscriptionStates.loading && !!subscription && (subscription?.type !== "recurring" || subscription?.status !== "active")){
    return <CheckoutContainer><InvalidSubscription message={"Oops! No puedes el método de pago de esta suscripción por que ya venció o no es un débito automatico"}/></CheckoutContainer>
  } 

  return (
    <>
      <CheckoutContainer>
        {!gatewayError && !appError && !success && !!subscription && (
          <>
            <Column>
              <MercadoPagoForm
                onError={onError}
                onSubmit={onSubmit}
                onSuccess={onSuccess}
                title={"Actualiza tu método de pago"}
                submitText={"Actualizar"}
                publicKey={subscription?.gateway?.credentials?.public?.public_key || ""}
                price={subscription?.current_amount || 10}
              />
            </Column>
            <SubscriptionDetails />
          </>
        )}
      </CheckoutContainer>

      {((gatewayError || appError) && !success) && <ErrorPaymentMethodUpdate />}
      {(!gatewayError && !appError && success) && <Success />}
    </>
  );
};

const state = ({ userStore, subscriptionAdminStore }) => {
  const { isLoggedIn } = userStore;
  const { data: subscription, states: subscriptionStates } = subscriptionAdminStore.subscriptionAdmin;

  return {
    isLoggedIn,
    subscription,
    subscriptionStates,
  };
};

export default connect(state)(Component);