import Grid from "../../../../../components/Grid";
import { Section, SectionName, Title } from "../StyledComponents";
import { Acordeon } from "./styles";
import constants from "../../../../../constants";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Group } from "../../../../../types";

interface ComponentProps {
  group: Group;
  groupStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const removedContentForExpertId2551 = (group, faqs) => {
  const itemsToBeRemoved = [
    "¿En qué día y horario son los encuentros en vivo?",
    "¿Qué pasa si el Klouser no cumple con mis expectativas?",
    "¿Por donde me llega el contenido?",
  ]

  if (group?.expert?.id === 2551 || group?.expert?.id !== 10165) {
    return faqs.filter(item => !itemsToBeRemoved.includes(item?.question))
  } else {
    return faqs
  }
}

const Component = ({ group }: ComponentProps) => {
  const [faqs, setFaqs] = useState<{ question: string; answer: string }[]>();

  useEffect(() => {
    const configFilter = constants.pageGroupDetails.filter(
      (config) => config.type === group?.group_type && config.group_id === group?.id
    );

    let config;

    if (configFilter.length > 0)
      config = configFilter[0]
    else {
      config = constants.pageGroupDetails.filter(
        (config) => config.type === group?.group_type && !config.group_id
      )[0];
    }

    setFaqs(removedContentForExpertId2551(group, config?.faqs));
  }, [group]);

  return (
    <Section>
      <SectionName>FAQS</SectionName>
      <Title>Preguntas frecuentes</Title>

      <div style={{ height: "44px" }} />

      <Grid.ContainerFluid>
        <Grid.Row>
          <Grid.Col sm={8} offset={{ sm: 2 }}>
            <Acordeon items={faqs} />
          </Grid.Col>
        </Grid.Row>
      </Grid.ContainerFluid>
    </Section>
  );
};

const state = ({ groupStore }) => {
  const { data: group, states: groupStates } = groupStore.group;

  return {
    group,
    groupStates,
  };
};

export default connect(state)(Component);
