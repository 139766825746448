import { FieldArray } from "formik";
import { Delete } from "./styles";
import Button from "../../../../../../components/Button";
import Icon from "../../../../../../components/Icon";
import Input from "../../../../../../components/FormikForm/Input";
import deleteCircleCross from "../../../../../../assets/icons/delete-circle-cross.svg";

interface ComponentProps {
  errors
  touched
  values
  onChange,
  onBlur,
  placeholder
}

const Component = ({
  errors,
  touched,
  values,
  onChange,
  onBlur,
  placeholder
}: ComponentProps) => {


  return (
    <FieldArray
      name="group_perks"
      render={({ insert, remove, push }) => (
        <>
          <div>
            {!!values.group_perks &&
              values.group_perks?.length > 0 &&
              values.group_perks.map((perk, index) => {
                return (
                  <>
                    <div
                      key={index}
                      style={{
                        marginBottom: "14px",
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "8px",
                      }}
                    >
                      <Input
                        type="text"
                        name={`group_perks.${index}`}
                        error={errors?.group_perks ? errors?.group_perks[index] : undefined}
                        touched={touched?.group_perks ? touched?.group_perks[index] : undefined}
                        value={perk}
                        placeholder={!!placeholder ? `${placeholder} ${index + 1}` : ""}
                        onChange={(event) => {
                          // if (event.target.value.length > 300) {
                          //   event.target.value = event.target.value.slice(0, 299); // Trim the input value
                          // }
                      
                          onChange(event)
                        }}
                        onBlur={onBlur}
                        max={300}
                        options={{
                          marginBottom: 0,
                        }}
                      />

                      {index !== 0 && (
                        <div
                          style={{
                            height: "40px",
                            display: "flex",
                          }}
                        >
                          <Delete
                            type="button"
                            onClick={() => remove(index)} // remove a question from the list
                          >
                            <Icon
                              icon={deleteCircleCross}
                              size="20px"
                              color="#96999B"
                            />
                          </Delete>
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
          </div>

          <Button
            type="button"
            options={{
              size: "lg",
              type: "link",
              skin: "purple",
            }}
            onClick={() => {
              insert(values?.group_perks?.length, "");
            }}
          >
            Agregar otro beneficio
          </Button>

          <div style={{ height: 40 }} />
        </>
      )}
    />
  );
};

export default Component;
