import { Group, User, ChargebeeSubscriptionPage, Expert, GetSubscriptionsOutDto} from "../../../types";

export const RESET_USER_INFORMATION = "RESET_USER_INFORMATION";
export const RESET_CHANGE_USER_EMAIL = "RESET_CHANGE_USER_EMAIL";

export const CHANGE_USER_EMAIL = "CHANGE_USER_EMAIL";
export const CHANGE_USER_EMAIL_SUCCESS = "CHANGE_USER_EMAIL_SUCCESS";
export const CHANGE_USER_EMAIL_ERROR = "CHANGE_USER_EMAIL_ERROR";

export const CHANGE_USERNAME = "CHANGE_USERNAME";
export const CHANGE_USERNAME_SUCCESS = "CHANGE_USERNAME_SUCCESS";
export const CHANGE_USERNAME_ERROR = "CHANGE_USER_EMAIL_ERROR";
export const RESET_CHANGE_USERNAME = "RESET_CHANGE_USERNAME";

export const CHANGE_USER_AVATAR = "CHANGE_USER_AVATAR";
export const CHANGE_USER_AVATAR_SUCCESS = "CHANGE_USER_AVATAR_SUCCESS";
export const CHANGE_USER_AVATAR_ERROR = "CHANGE_USER_EMAIL_ERROR";
export const RESET_CHANGE_USER_AVATAR = "RESET_CHANGE_USER_AVATAR";

export const CREATE_NEW_EXPERT = "CREATE_NEW_EXPERT";
export const CREATE_NEW_EXPERT_SUCCESS = "CREATE_NEW_EXPERT_SUCCESS";
export const CREATE_NEW_EXPERT_ERROR = "CREATE_NEW_EXPERT_ERROR";

export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_ERROR = "CHANGE_USER_PASSWORD_ERROR";

export const CHANGE_USER_PHONE = "CHANGE_USER_PHONE";
export const CHANGE_USER_PHONE_SUCCESS = "CHANGE_USER_PHONE_SUCCESS";
export const CHANGE_USER_PHONE_ERROR = "CHANGE_USER_PHONE_ERROR";
export const RESET_CHANGE_USER_PHONE = "RESET_CHANGE_USER_PHONE";

export const GET_USER_INFORMATION = "GET_USER_INFORMATION";
export const GET_USER_INFORMATION_SUCCESS = "GET_USER_INFORMATION_SUCCESS";
export const GET_USER_INFORMATION_ERROR = "GET_USER_INFORMATION_ERROR";

export const UPDATE_CURRENT_USER_INFORMATION =
  "UPDATE_CURRENT_USER_INFORMATION";
export const UPDATE_CURRENT_USER_INFORMATION_SUCCESS =
  "UPDATE_CURRENT_USER_INFORMATION_SUCCESS";
export const UPDATE_CURRENT_USER_INFORMATION_ERROR =
  "UPDATE_CURRENT_USER_INFORMATION_ERROR";

export const GET_USER_SUBSCRIPTIONS = "GET_USER_SUBSCRIPTIONS";
export const GET_USER_SUBSCRIPTIONS_SUCCESS = "GET_USER_SUBSCRIPTIONS_SUCCESS";
export const GET_USER_SUBSCRIPTIONS_ERROR = "GET_USER_SUBSCRIPTIONS_ERROR";

export const GET_USER_FREE_SUBSCRIPTIONS = "GET_USER_FREE_SUBSCRIPTIONS";
export const GET_USER_FREE_SUBSCRIPTIONS_SUCCESS = "GET_USER_FREE_SUBSCRIPTIONS_SUCCESS";
export const GET_USER_FREE_SUBSCRIPTIONS_ERROR = "GET_USER_FREE_SUBSCRIPTIONS_ERROR";

export const GET_USER_GROUPS = "GET_USER_GROUPS";
export const GET_USER_GROUPS_SUCCESS = "GET_USER_GROUPS_SUCCESS";
export const GET_USER_GROUPS_ERROR = "GET_USER_GROUPS_ERROR";

export const UPDATE_CURRENT_USER_GROUPS = "UPDATE_CURRENT_USER_GROUPS";
export const UPDATE_CURRENT_USER_GROUPS_SUCCESS =
  "UPDATE_CURRENT_USER_GROUPS_SUCCESS";
export const UPDATE_CURRENT_USER_GROUPS_ERROR =
  "UPDATE_CURRENT_USER_GROUPS_ERROR";

export const UPDATE_EXPERT_INFORMATION = "UPDATE_EXPERT_INFORMATION";
export const UPDATE_EXPERT_INFORMATION_SUCCESS =
  "UPDATE_EXPERT_INFORMATION_SUCCESS";
export const UPDATE_EXPERT_INFORMATION_ERROR =
  "UPDATE_EXPERT_INFORMATION_ERROR";

export const GET_SUBSCRIPTIONS_MANAGER_URL = "GET_SUBSCRIPTIONS_MANAGER_URL";
export const GET_SUBSCRIPTIONS_MANAGER_URL_SUCCESS =
  "GET_SUBSCRIPTIONS_MANAGER_URL_SUCCESS";
export const GET_SUBSCRIPTIONS_MANAGER_URL_ERROR =
  "GET_SUBSCRIPTIONS_MANAGER_URL_ERROR";

export const CHANGE_TRACKING = "CHANGE_TRACKING";
export const CHANGE_TRACKING_SUCCESS = "CHANGE_TRACKING_SUCCESS";
export const CHANGE_TRACKING_ERROR = "CHANGE_USER_EMAIL_ERROR";
export const RESET_CHANGE_TRACKING = "RESET_CHANGE_TRACKING";

export const USER_IS_LOGGEDIN = "USER_IS_LOGGED_IN";

interface NewExpertInformation {
  username: string;
  avatar: any;
}

export interface CreateNewExpert {
  type: typeof CREATE_NEW_EXPERT;
  payload: NewExpertInformation;
}
export interface CreateNewExpertSuccess {
  type: typeof CREATE_NEW_EXPERT_SUCCESS;
  payload: null;
}
export interface CreateNewExpertError {
  type: typeof CREATE_NEW_EXPERT_ERROR;
  payload: boolean | string;
}

export interface UpdateExpertInformation {
  type: typeof UPDATE_EXPERT_INFORMATION;
  payload: User;
}
export interface UpdateExpertInformationSuccess {
  type: typeof UPDATE_EXPERT_INFORMATION_SUCCESS;
  payload: null;
}
export interface UpdateExpertInformationError {
  type: typeof UPDATE_EXPERT_INFORMATION_ERROR;
  payload: boolean | string;
}
export interface ResetUserInformation {
  type: typeof RESET_USER_INFORMATION;
  payload: null;
}
export interface ResetChangeUserEmail {
  type: typeof RESET_CHANGE_USER_EMAIL;
  payload: null;
}
export interface ChangeUserEmail {
  type: typeof CHANGE_USER_EMAIL;
  payload: { email: string; userId: number };
}
export interface ChangeUserEmailSuccess {
  type: typeof CHANGE_USER_EMAIL_SUCCESS;
  payload: null;
}
export interface ChangeUserEmailError {
  type: typeof CHANGE_USER_EMAIL_ERROR;
  payload: boolean | string;
}

export interface ChangeUsername {
  type: typeof CHANGE_USERNAME;
  payload: string;
}
export interface ChangeUsernameSuccess {
  type: typeof CHANGE_USERNAME_SUCCESS;
  payload: null;
}
export interface ChangeUsernameError {
  type: typeof CHANGE_USERNAME_ERROR;
  payload: boolean | string;
}
export interface ResetChangeUsername {
  type: typeof RESET_CHANGE_USERNAME;
  payload: null;
}

export interface ChangeUserAvatar {
  type: typeof CHANGE_USER_AVATAR;
  payload: string;
}
export interface ChangeUserAvatarSuccess {
  type: typeof CHANGE_USER_AVATAR_SUCCESS;
  payload: null;
}
export interface ChangeUserAvatarError {
  type: typeof CHANGE_USER_AVATAR_ERROR;
  payload: boolean | string;
}
export interface ResetChangeUserAvatar {
  type: typeof RESET_CHANGE_USER_AVATAR;
  payload: null;
}

export interface ChangeUserPassword {
  type: typeof CHANGE_USER_PASSWORD;
  payload: string;
}
export interface ChangeUserPasswordSuccess {
  type: typeof CHANGE_USER_PASSWORD_SUCCESS;
  payload: null;
}
export interface ChangeUserPasswordError {
  type: typeof CHANGE_USER_PASSWORD_ERROR;
  payload: null;
}

export interface ChangeUserPhone {
  type: typeof CHANGE_USER_PHONE;
  payload: string;
}
export interface ChangeUserPhoneSuccess {
  type: typeof CHANGE_USER_PHONE_SUCCESS;
  payload: null;
}
export interface ChangeUserPhoneError {
  type: typeof CHANGE_USER_PHONE_ERROR;
  payload: string | boolean;
}
export interface ResetChangeUserPhone {
  type: typeof RESET_CHANGE_USER_PHONE;
  payload: null;
}

export interface GetUserInformation {
  type: typeof GET_USER_INFORMATION;
  payload: null;
}
export interface GetUserInformationSuccess {
  type: typeof GET_USER_INFORMATION_SUCCESS;
  payload: User;
}
export interface GetUserInformationError {
  type: typeof GET_USER_INFORMATION_ERROR;
  payload: boolean | string;
}
export interface UpdateCurrentUserInformation {
  type: typeof UPDATE_CURRENT_USER_INFORMATION;
  payload: null;
}
export interface UpdateCurrentUserInformationSuccess {
  type: typeof UPDATE_CURRENT_USER_INFORMATION_SUCCESS;
  payload: User;
}
export interface UpdateCurrentUserInformationError {
  type: typeof UPDATE_CURRENT_USER_INFORMATION_ERROR;
  payload: boolean | string;
}
export interface GetUserSubscriptions {
  type: typeof GET_USER_SUBSCRIPTIONS;
  payload?: GetSubscriptionsOutDto;
}
export interface GetUserSubscriptionsSuccess {
  type: typeof GET_USER_SUBSCRIPTIONS_SUCCESS;
  payload: Group[];
}
export interface GetUserSubscriptionsError {
  type: typeof GET_USER_SUBSCRIPTIONS_ERROR;
  payload: boolean | string;
}

export interface GetUserFreeSubscriptions {
  type: typeof GET_USER_FREE_SUBSCRIPTIONS;
  payload: null;
}
export interface GetUserFreeSubscriptionsSuccess {
  type: typeof GET_USER_FREE_SUBSCRIPTIONS_SUCCESS;
  payload: Group[];
}
export interface GetUserFreeSubscriptionsError {
  type: typeof GET_USER_FREE_SUBSCRIPTIONS_ERROR;
  payload: boolean | string;
}
export interface GetUserGroups {
  type: typeof GET_USER_GROUPS;
  payload: null;
}
export interface GetUserGroupsSuccess {
  type: typeof GET_USER_GROUPS_SUCCESS;
  payload: Group[];
}
export interface GetUserGroupsError {
  type: typeof GET_USER_GROUPS_ERROR;
  payload: boolean | string;
}

export interface UpdateCurrentUserGroups {
  type: typeof UPDATE_CURRENT_USER_GROUPS;
  payload: null;
}
export interface UpdateCurrentUserGroupsSuccess {
  type: typeof UPDATE_CURRENT_USER_GROUPS_SUCCESS;
  payload: Group[];
}
export interface UpdateCurrentUserGroupsError {
  type: typeof UPDATE_CURRENT_USER_GROUPS_ERROR;
  payload: boolean | string;
}

export interface GetSubscriptionsManagerUrl {
  type: typeof GET_SUBSCRIPTIONS_MANAGER_URL;
  payload: null;
}
export interface GetSubscriptionsManagerUrlSuccess {
  type: typeof GET_SUBSCRIPTIONS_MANAGER_URL_SUCCESS;
  payload: ChargebeeSubscriptionPage;
}
export interface GetSubscriptionsManagerUrlError {
  type: typeof GET_SUBSCRIPTIONS_MANAGER_URL_ERROR;
  payload: boolean | string;
}

export interface UserIsLoggedId {
  type: typeof USER_IS_LOGGEDIN;
  payload: boolean;
}

export interface ChangeTracking {
  type: typeof CHANGE_TRACKING;
  payload: string;
}
export interface ChangeTrackingSuccess {
  type: typeof CHANGE_TRACKING_SUCCESS;
  payload: null;
}
export interface ChangeTrackingError {
  type: typeof CHANGE_TRACKING_ERROR;
  payload: boolean | string;
}
export interface ResetChangeTracking {
  type: typeof RESET_CHANGE_TRACKING;
  payload: null;
}

export interface State {
  subscriptions: {
    data: Group[] | null;
    states: {
      success: boolean;
      loading: boolean;
      error: boolean | string;
    };
  };
  groups: {
    data: Group[] | null;
    states: {
      success: boolean;
      loading: boolean;
      error: boolean | string;
    };
  };
  information: {
    data: User | null;
    states: {
      success: boolean;
      loading: boolean;
      error: boolean | string;
    };
  };
  changeUserEmail: {
    states: {
      success: boolean;
      loading: boolean;
      error: boolean | string;
    };
  };
  changeUserPassword: {
    states: {
      success: boolean;
      loading: boolean;
      error: boolean | string;
    };
  };
  subscruptionsManagerUrl: {
    data: ChargebeeSubscriptionPage | null;
    states: {
      success: boolean;
      loading: boolean;
      error: boolean | string;
    };
  };
  createNewExpert: {
    states: {
      success: boolean;
      loading: boolean;
      error: boolean | string;
    };
  };
  updateExpertInformation: {
    states: {
      success: boolean;
      loading: boolean;
      error: boolean | string;
    };
  };
  changeUsername: {
    states: {
      success: boolean;
      loading: boolean;
      error: boolean | string;
    };
  };
  changeUserPhone: {
    states: {
      success: boolean;
      loading: boolean;
      error: boolean | string;
    };
  };
  changeUserAvatar: {
    states: {
      success: boolean;
      loading: boolean;
      error: boolean | string;
    };
  };
  changeTracking: {
    states: {
      success: boolean;
      loading: boolean;
      error: boolean | string;
    };
  };
  isLoggedIn: boolean;
}

export const initialState: State = {
  subscriptions: {
    data: null,
    states: {
      success: false,
      loading: false,
      error: false,
    },
  },
  groups: {
    data: null,
    states: {
      success: false,
      loading: false,
      error: false,
    },
  },
  information: {
    data: null,
    states: {
      success: false,
      loading: false,
      error: false,
    },
  },
  changeUserEmail: {
    states: {
      success: false,
      loading: false,
      error: false,
    },
  },
  changeUserPassword: {
    states: {
      success: false,
      loading: false,
      error: false,
    },
  },
  changeUserPhone: {
    states: {
      success: false,
      loading: false,
      error: false,
    },
  },
  subscruptionsManagerUrl: {
    data: null,
    states: {
      success: false,
      loading: false,
      error: false,
    },
  },
  createNewExpert: {
    states: {
      success: false,
      loading: false,
      error: false,
    },
  },
  updateExpertInformation: {
    states: {
      success: false,
      loading: false,
      error: false,
    },
  },
  changeUsername: {
    states: {
      success: false,
      loading: false,
      error: false,
    },
  },
  changeUserAvatar: {
    states: {
      success: false,
      loading: false,
      error: false,
    },
  },
  changeTracking: {
    states: {
      success: false,
      loading: false,
      error: false,
    },
  },
  isLoggedIn: false,
};

export type Actions =
  | ResetUserInformation
  | ResetChangeUserEmail
  | GetUserInformation
  | GetUserInformationSuccess
  | GetUserInformationError
  | UpdateCurrentUserInformation
  | UpdateCurrentUserInformationSuccess
  | UpdateCurrentUserInformationError
  | ChangeUserEmail
  | ChangeUserEmailSuccess
  | ChangeUserEmailError
  | ChangeUserPassword
  | ChangeUserPasswordSuccess
  | ChangeUserPasswordError
  | GetUserSubscriptions
  | GetUserSubscriptionsSuccess
  | GetUserSubscriptionsError
  | GetUserFreeSubscriptions
  | GetUserFreeSubscriptionsSuccess
  | GetUserFreeSubscriptionsError
  | GetUserGroups
  | GetUserGroupsSuccess
  | GetUserGroupsError
  | UpdateCurrentUserGroups
  | UpdateCurrentUserGroupsSuccess
  | UpdateCurrentUserGroupsError
  | GetSubscriptionsManagerUrl
  | GetSubscriptionsManagerUrlSuccess
  | GetSubscriptionsManagerUrlError
  | CreateNewExpert
  | CreateNewExpertSuccess
  | CreateNewExpertError
  | UpdateExpertInformation
  | UpdateExpertInformationSuccess
  | UpdateExpertInformationError
  | ChangeUsername
  | ChangeUsernameSuccess
  | ChangeUsernameError
  | ResetChangeUsername
  | ChangeTracking
  | ChangeTrackingSuccess
  | ChangeTrackingError
  | ResetChangeTracking
  | ChangeUserAvatar
  | ChangeUserAvatarSuccess
  | ChangeUserAvatarError
  | ResetChangeUserAvatar
  | ChangeUserPhone
  | ChangeUserPhoneSuccess
  | ChangeUserPhoneError
  | ResetChangeUserPhone
  | UserIsLoggedId;
