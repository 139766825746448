import styled from "styled-components";
import { rainbowTextGradient } from "../../../../../components/StyledComponents";

export const Title = styled("h1")`
  margin: 0px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
`;

export const SectionName = styled("h4")`
  margin: 0px;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  text-align: center;
  ${rainbowTextGradient};

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    letter-spacing: 2.5px;
  }
`;


export const GroupName = styled('h3')`
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #1A1A1A;
`

export const Line = styled('div')`
  height: 1px;
  width: 100%;
  background-color: #2D3436;
  opacity: .5;
`

export const Price = styled('p')`
  margin: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2D3436;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Discount = styled('p')`
  margin: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2D3436;

  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: #12B47A;
  }
`

export const Total = styled('p')`
  margin: 0px;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #000000;

  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    small {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      color: rgb(45, 52, 54);
    }
  }
`

export const Currency = styled('p')`
  margin: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #69779B;
`
export const FooterMessage = styled('p')`
  margin: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #69779B;
`

export const Column = styled('div')`
 width: 100%;
 display: flex;
 justify-content: flex-start;
 align-items: flex-start;
 flex-shrink: 0;
 padding: 30px 20px;
 background-color: #FAFAFA;
 height: auto;

 @media(min-width: 768px){
  width: 50%;
  min-height: 100vh;
  padding: 40px 80px;
 }
`