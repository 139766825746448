import { Title, Message, ProfileImage, Column, Link, SmallMessage } from "./styles";
import { connect } from "react-redux";


const Component = ({ group, user }) => {
  // const [countdown, setCountdown] = useState(5);

  // useEffect(() => {
  //   if (countdown > 0) {
  //     const timer = setTimeout(() => {
  //       setCountdown(countdown - 1);
  //     }, 1000);

  //     return () => clearTimeout(timer);
  //   } else {
  //     window.location.href = `/group/${groupSlug}/survey`;
  //   }
  // }, [countdown, groupSlug]);

  return (
    <Column>
    
      <Title>¡Actualizaste tu método de pago​!</Title>
      <div style={{ height: 20 }} />
      <Message>A partir de ahora realizaremos los cobros al nuevo método de pago proporcionado</Message>
      <div style={{ height: 35 }} />
      <div style={{maxWidth: "400px"}}>
        <Link options={{ type: 'filled', size: 'lg', skin: 'purple', block: true }} href={`/profile/subscriptions`}>Ir a Mis Suscripciones</Link>
      </div>
      <div style={{ height: 28 }} />
      <SmallMessage>Toca "Ir a Mis Suscripciones"</SmallMessage>
    </Column>
  )
}

const state = ({ groupStore, userStore }) => {
  const { data: group } = groupStore?.group;
  const { data: user } = userStore?.information

  return { group, user };
};

export default connect(state)(Component);