import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getGroupBySlug } from '../../../store/actions/group';
import { Layout, useLayout } from '../../../providers/LayoutProvider';
import { Group, PlanDto, Subscription } from '../../../types';
import Success from './components/Success';
import ErrorPayment from './components/ErrorPayment';
import ErrorSubscription from './components/ErrorSubscription';
import { getUrlParamByName } from '../../../helpers/get-url-param';
import { getFreeSubscriptions } from '../../../helpers/getFreeSubscriptions';
import { isSubscribed } from '../../../helpers/isSubscribed';
import PurchaseDetails from './components/PurchaseDetails';
import { CheckoutContainer, Column } from './styles'
import { hideModal, showModal } from '../../../store/actions/modal';
import ProcessSubscriptionModal from './components/ProcessSubscriptionModal';
import action from "../../../helpers/rest-client";
import MercadoPagoForm from '../components/MercadoPagoForm';

interface ComponentProps {
  isLoggedIn: boolean
  userSubscriptions: Subscription[]
  userSubscriptionStates: {
    loading: boolean
    success: boolean
    error: string | boolean
  }
  group: Group
}

const Component = ({
  isLoggedIn,
  userSubscriptions,
  userSubscriptionStates,
  group,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const { setLayout, layout } = useLayout();

  const [gatewayError, setGatewayError] = useState(false)
  const [appError, setAppError] = useState(false)
  const [success, setSuccess] = useState(false)

  const groupSlug = useMemo(() => getUrlParamByName('groupSlug'), [])
  const planId = useMemo(() => getUrlParamByName('planId'), [])

  const onSuccess = (subscription) => {
    setSuccess(true)
    setGatewayError(false)
    setAppError(false)
  }

  const onError = (error) => {
    if (error?.response?.data?.source === 'gateway') {
      setGatewayError(true)
    } else if (error?.response?.data?.source === 'application') {
      setAppError(true)
    } else {
      setAppError(true)
    }
    setSuccess(false)
  }

  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "rainbow",
      footer: { visible: false },
      header: { visible: false },
      toolbar: { visible: false },
      body: {
        visible: true,
        background: { color: 'white' },
      },
    }),
    []
  );

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(userSubscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [userSubscriptions, group, isLoggedIn])

  const price = useMemo(() => {
    if (planId) {
      const selectedPlan: PlanDto | undefined = group?.plans.find((item) => item.id === Number(planId));
      const selectedPlanDiscount = selectedPlan?.discounts[0]
      return (selectedPlan?.price || 0) - (selectedPlanDiscount?.amount || 0);
    }

    return 0
  }, [group, planId])

  const publicKey = useMemo(() => {
    if (planId) {
      const selectedPlan: PlanDto | undefined = group?.plans.find((item) => item.id === Number(planId));
      return selectedPlan?.gateway?.credentials?.public?.public_key;
    }

    return 0
  }, [group, planId])

  const discount = useMemo(() => {
    if (planId) {
      const selectedPlan: PlanDto | undefined = group?.plans.find((item) => item.id === Number(planId));
      const selectedPlanDiscount = selectedPlan?.discounts[0]
      return selectedPlanDiscount && [selectedPlanDiscount]
    }

    return []
  }, [group, planId])

  const onSubmit = useCallback(async (cardData) => {
    dispatch(showModal('modal-process-new-subscription'))

    const payload = {
      plan_id: planId,
      discounts: discount,
      payment_details: cardData
    }

    action.Post({
      url: "/api/subscription/payment",
      body: payload,
    }).then((response: Subscription) => {
      onSuccess(response)
    }).catch((error) => {
      onError(error)
    }).finally(() => {
      dispatch(hideModal())
    });
  }, [discount, price, planId]);

  useEffect(() => {
    if (!!groupSlug && (!group || (!!group && group?.slug !== groupSlug))) {
      dispatch(getGroupBySlug(groupSlug));
    }
  }, [groupSlug]);

  useEffect(() => {
    setLayout(layoutData);
  }, [group, layoutData]);

  useEffect(() => {
    if (isUserSubscribed) {
      window.location.href = `/group/${groupSlug}/library`
    }
  }, [isUserSubscribed, groupSlug])

  useEffect(() => {
    if (!planId || !groupSlug) {
      const referrer = document.referrer;
      if (referrer) {
        window.location.href = referrer;
      } else if (groupSlug) {
        window.location.href = `/group/${groupSlug}`;
      } else {
        window.location.href = `/404`;
      }
    }
  }, [planId, groupSlug])


  if (!isLoggedIn || !group || userSubscriptionStates.loading) {
    return <></>
  }

  return (
    <>
      <CheckoutContainer>
        {!gatewayError && !appError && !success && (
          <>
            <Column>
              <MercadoPagoForm
                onError={onError}
                onSuccess={onSuccess}
                onSubmit={onSubmit}
                publicKey={publicKey || ""}
                title={"Datos de pago"}
                price={price}
                submitText="Suscribirme"
              />
            </Column>
            <PurchaseDetails />
          </>
        )}
      </CheckoutContainer>

      <ProcessSubscriptionModal />

      {(!gatewayError && appError && !success) && <ErrorSubscription />}
      {(gatewayError && !appError && !success) && <ErrorPayment />}
      {(!gatewayError && !appError && success) && <Success />}
    </>
  );
};

const state = ({ userStore, groupStore, }) => {
  const { isLoggedIn } = userStore;
  const { data: userSubscriptions, states: userSubscriptionStates } = userStore.subscriptions;
  const { data: group } = groupStore?.group;

  return {
    isLoggedIn,
    userSubscriptions,
    userSubscriptionStates,
    group
  };
};

export default connect(state)(Component);