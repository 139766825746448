import { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Formik } from "formik";
import GatewayCard from './components/GatewayCard'
import { SectionTitle, Text, EmptyPlanCard } from './styles'
import Loader from "../../../../../components/Loader";
import Button from '../../../../../components/Button'
import Grid from '../../../../../components/Grid'
import { showModal } from '../../../../../store/actions/modal'
import { PlanDto } from '../../../../../types/plan.dto'
import flagArgentina from '../../../../../constants/countries/flags/flag-AR.png'
import flagUnitedStates from '../../../../../constants/countries/flags/flag-US.png'
import flagEuropeanUnion from '../../../../../constants/countries/flags/flag-UE.png'
import { schema, initialValues } from '../../../../../constants/forms/plan/main-currency'
import { Center } from '../../../../../components/StyledComponents';
import { linkGateway } from '../../../../../store/actions/expert';
import { Gateway } from '../../../../../types/gateway';

const currencies = [
  {
    flag: flagArgentina,
    iso: "ARS",
    label: "AR$",
    description: "Pesos Argentinos (ARS)",
  },
  {
    flag: flagEuropeanUnion,
    iso: "EUR",
    label: "€",
    description: "Euros (EUR)",
  },
  {
    flag: flagUnitedStates,
    iso: "USD",
    label: "US$",
    description: "Dolares Americanos (USD)",
  },
];

interface ComponentProps {
  userData;
  userStates;
  setShowNewGatewaySidebar
}

const Component = ({ userData, userStates, setShowNewGatewaySidebar }: ComponentProps) => {
  const dispatch = useDispatch()
  const [formSubmmited, setFormSubmmited] = useState(false);

  const isExpert = useMemo((): boolean => {return !!userData?.expert}, [userData]);
  const primaryGateway = useMemo((): Gateway => userData?.expert?.linked_gateways?.find(lg => lg.priority === "primary" && lg.currency === "ARS"), [userData])


  const onSubmit = (values) => {
    dispatch(linkGateway({ ...values }))
    window.location.href = "/profile/gateways"
  }

  if(!isExpert && !userStates.loading)
    window.location.href = "/profile/my-account"

  return (
    <>
    {!primaryGateway && (
      <Grid.Row>
        <Grid.Col>
          <Button
            options={{
              type: "outline",
              skin: "purple",
              size: "lg",
            }}
            onClick={() => setShowNewGatewaySidebar(true)}
          >
            + Agregar nuevo gateway
          </Button>
        </Grid.Col>
      </Grid.Row>
    )}

      <div style={{ height: 40 }} />

      {!primaryGateway ? (
        <>
          <Grid.Row>
            <Grid.Col>
              <EmptyPlanCard>Aun no has conectado ninguna pasarela de pago</EmptyPlanCard>
            </Grid.Col>
          </Grid.Row>
          <div style={{ height: 15 }} />
        </>
      ) : (
        <GatewayCard gateway={primaryGateway}/> 
      )}

    </>
  )
}

const state = ({ expertStore, userStore }) => {
  const { data: expert } = expertStore.linkGateway


  const { data: userData, states: userStates } = userStore.information;

  return {
    expert,
    userData,
    userStates
  };
};

export default connect(state)(Component);
