import styled from "styled-components";
import Image from "../../../../../components/Image";

export const Column = styled('div')`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 45px 20px;
  max-width: 375px;
  margin: 0 auto;
`

export const Title = styled("h3")`
  margin: 0px;
  font-size: 24px;
  font-weight: 500;
`;

export const Message = styled('p')`
  margin: 0px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`

export const ProfileImage = styled(Image)`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 2px solid white;
  display: block;
  border: 3px solid #EC221F;
`