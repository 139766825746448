import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getGroupBySlug } from '../../../store/actions/group';
import { Layout, useLayout } from '../../../providers/LayoutProvider';
import { Group, PlanDto, Subscription } from '../../../types';
import Success from './components/Success';
import ErrorPayment from './components/ErrorPayment';
import ErrorSubscription from './components/ErrorSubscription';
import { getUrlParamByName } from '../../../helpers/get-url-param';
import { getFreeSubscriptions } from '../../../helpers/getFreeSubscriptions';
import { isSubscribed } from '../../../helpers/isSubscribed';
import PurchaseDetails from './components/PurchaseDetails';
import { CheckoutContainer, Column } from './styles'
import action from "../../../helpers/rest-client";
import MercadoPagoForm from '../components/MercadoPagoForm';

interface ComponentProps {
  isLoggedIn: boolean
  userSubscriptions: Subscription[]
  userSubscriptionStates: {
    loading: boolean
    success: boolean
    error: string | boolean
  }
  group: Group
}

const Component = ({
  isLoggedIn,
  userSubscriptions,
  userSubscriptionStates,
  group,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<{
    group: Group
    plan: PlanDto
    subscription,
    migrationStatus: string
  }>()
  const { setLayout, layout } = useLayout();

  const [gatewayError, setGatewayError] = useState(false)
  const [appError, setAppError] = useState(false)
  const [success, setSuccess] = useState(false)

  const onSuccess = (subscription) => {
    setSuccess(true)
    setGatewayError(false)
    setAppError(false)
  }

  const onError = (error) => {
    if (error?.response?.data?.source === 'gateway') {
      setGatewayError(true)
    } else if (error?.response?.data?.source === 'application') {
      setAppError(true)
    } else {
      setAppError(true)
    }
    setSuccess(false)
  }

  const token = useMemo(() => getUrlParamByName('t'), [])

  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "rainbow",
      footer: { visible: false },
      header: { visible: false },
      toolbar: { visible: false },
      body: {
        visible: true,
        background: { color: 'white' },
      },
    }),
    []
  );

  //http://localhost:1337/admin/plugins/content-manager/collectionType/application::subscriptions.subscriptions/127256

  useEffect(() => {
    if (!!token) {
      // dispatch(getSubscriptionBy(token));

      action.Get({
        url: "/api/subscription/migration-subscription-data",
        body: {},
        config: {
          headers: {
            "x-token": token
          }
        }
      }).then((response) => {
        setData(response)
      }).catch((error) => {
        console.log(error)
      });
    }
  }, [token]);


  // const isUserSubscribed = useMemo(() => {
  //   if (isLoggedIn) {
  //     return isSubscribed(userSubscriptions, group?.id)
  //   } else if (!isLoggedIn || group?.group_type === 'free_experience') {
  //     return isSubscribed(getFreeSubscriptions(), group?.id)
  //   } else {
  //     return false
  //   }
  // }, [userSubscriptions, group, isLoggedIn])

  // useEffect(() => {
  //   if (!!groupSlug && (!group || (!!group && group?.slug !== groupSlug))) {
  //     dispatch(getGroupBySlug(groupSlug));
  //   }
  // }, [groupSlug]);

  useEffect(() => {
    setLayout(layoutData);
  }, [group, layoutData]);

  // useEffect(() => {
  //   if (isUserSubscribed) {
  //     window.location.href = `/group/${groupSlug}/library`
  //   }
  // }, [isUserSubscribed, groupSlug])

  // useEffect(() => {
  //   if (!data?.plan?.id || !data?.group?.slug) {
  //     const referrer = document.referrer;
  //     if (referrer) {
  //       window.location.href = referrer;
  //     } else {
  //       window.location.href = `/404`;
  //     }
  //   }
  // }, [data?.plan, data?.group])

  const price = useMemo(() => {
    if (data?.plan?.id) {
      const selectedPlan: PlanDto | undefined = data?.group?.plans.find((item) => item.id === Number(data?.plan?.id));
      const selectedPlanDiscount = selectedPlan?.discounts ? selectedPlan?.discounts[0] : null;
      return (selectedPlan?.price || 0) - (selectedPlanDiscount?.amount || 0);
    }

    return 0
  }, [data?.group, data?.plan])

  const publicKey = useMemo(() => {
    if (data?.plan?.id) {
      const selectedPlan: PlanDto | undefined = group?.plans.find((item) => item.id === Number(data?.plan?.id));
      return selectedPlan?.gateway?.credentials?.public?.public_key || "";
    }

    return ""
  }, [data?.group, data?.plan])

  const discount = useMemo(() => {
    if (data?.plan?.id) {
      const selectedPlan: PlanDto | undefined = group?.plans.find((item) => item.id === Number(data?.plan?.id));
      const selectedPlanDiscount = selectedPlan?.discounts[0]
      return selectedPlanDiscount && [selectedPlanDiscount]
    }

    return []
  }, [data?.group, data?.plan])

  const onSubmit = useCallback(async (cardData) => {
    // const payload = {
    //   plan_id: planId,
    //   discounts: discount,
    //   payment_details: cardData
    // }

    // console.log(payload)

    // action.Post({
    //   url: "/api/subscription/payment",
    //   body: payload,
    // }).then((response: Subscription) => {
    //   onSuccess(response)
    // }).catch((error) => {
    //   onError(error)
    // });
  }, [discount, price, data?.plan?.id]);

  // if (!isLoggedIn || !group || userSubscriptionStates.loading) {
  //   return <></>
  // }

  return (
    <>
      <CheckoutContainer>
        {!gatewayError && !appError && !success && (
          <>
            <Column>
              <MercadoPagoForm
                onError={onError}
                onSuccess={onSuccess}
                onSubmit={onSubmit}
                publicKey={publicKey}
                title={"Datos de pago"}
                price={price}
                submitText="Suscribirme"
              />
            </Column>
            <PurchaseDetails group={data?.group} planId={data?.plan?.id} />
          </>
        )}
      </CheckoutContainer>

      {(!gatewayError && appError && !success) && <ErrorSubscription />}
      {(gatewayError && !appError && !success) && <ErrorPayment />}
      {(!gatewayError && !appError && success) && <Success />}
    </>
  );
};

const state = ({ userStore, groupStore, }) => {
  const { isLoggedIn } = userStore;
  const { data: userSubscriptions, states: userSubscriptionStates } = userStore.subscriptions;
  const { data: group } = groupStore?.group;

  return {
    isLoggedIn,
    userSubscriptions,
    userSubscriptionStates,
    group
  };
};

export default connect(state)(Component);