import styled from "styled-components";
import UiLink from '../../../../../components/Link';
import Image from "../../../../../components/Image";
import conffetiImage from "../../../../../assets/conffeti-bg.jpg"

export const Column = styled('div')`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 45px 20px;
  max-width: 700px;
  margin: 0 auto;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0px;
    left: 0px;
    background-image: url(${conffetiImage});
    background-repeat: repeat-x;
  }
`

export const Title = styled("h3")`
  margin: 0px;
  font-size: 24px;
  font-weight: 500;
  line-height: 2px;
`;

export const Message = styled('p')`
  margin: 0px;
  font-size: 18px;
  max-width: 400px;
  line-height: 24px;
  text-align: center;
  color: #4E5253;

  span {
    font-weight: 600;
  }
`

export const ProfileImage = styled(Image)`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 2px solid white;
  display: block;
  border: 3px solid #14AE5C;
`

export const Link = styled(UiLink)`
  background-color: #14AE5C;
  border-color: #14AE5C
`

export const SmallMessage = styled('p')`
  text-align: center;
  margin: 0px;
  font-size: 16px;
  line-height: 26px;
`