import { Title, Message, ProfileImage, Column, Link, SmallMessage } from "./styles";
import { connect } from "react-redux";
import userAvatar from "../../../../../assets/avatar.svg";
import { useEffect, useMemo, useState } from 'react';
import { getUrlParamByName } from "../../../../../helpers/get-url-param";

const groupTypes = {
  community: "comunidad",
  free_experience: "taller",
  experience: "taller",
  membership: "membresía",
};

const Component = ({ group, user }) => {
  const [countdown, setCountdown] = useState(5);

  const groupSlug = useMemo(() => getUrlParamByName('groupSlug'), [])

  const text = useMemo(() => {
    const preffix = ['community', 'membership'].includes(group?.group_type) ? 'a la' : 'al';
    return `Ir ${preffix} ${groupTypes[group?.group_type]}`
  }, [group])

  const profilePicture = useMemo(() => {
    return group?.expert?.profile_pic?.url || userAvatar
  }, [group])

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      window.location.href = `/group/${groupSlug}/survey`;
    }
  }, [countdown, groupSlug]);

  return (
    <Column>
      <ProfileImage loading="lazy" src={profilePicture} alt={"Profile Image"} />
      <div style={{ height: 35 }} />
      <Title>¡Pago realizado con éxito!​</Title>
      <div style={{ height: 20 }} />
      <Message>{user?.name} felicitaciones 🎉 ya eres miembro de: <span>“{group?.group_name}"</span></Message>
      <div style={{ height: 35 }} />
      <Link options={{ type: 'filled', size: 'lg', skin: 'purple', block: true }} href={`/group/${groupSlug}`}>{text}</Link>
      <div style={{ height: 28 }} />
      <SmallMessage>Toca "{text}" o aguarda ({countdown} seg.)<br />y seras redirigido </SmallMessage>
    </Column>
  )
}

const state = ({ groupStore, userStore }) => {
  const { data: group } = groupStore?.group;
  const { data: user } = userStore?.information

  return { group, user };
};

export default connect(state)(Component);