import { Column, Title, Message, ProfileImage } from "./styles";
import Button from '../../../../../components/Button';
import cross from "../../../../../assets/cross.svg";
import Icon from "../../../../../components/Icon"

const Component = () => {

  return (
    <Column>
      <div style={{borderRadius: "100px", border: "1px solid red", padding: "10px"}}>
      <Icon size={"30px"} icon={cross} color={"red"}></Icon>
      </div>
      <div style={{ height: 20 }} />
      <Title>No pudimos actualizar tu método de pago</Title>
      <div style={{ height: 20 }} />
      <Message>Al intentar actualizar tu método de pago hubo un error proveniente de tu tarjeta o de tu banco. Por favor revisa que todos los datos sean correctos o vuelve a intentarlo con otra tarjeta.</Message>
      <div style={{ height: 35 }} />
      <Button options={{ type: 'filled', size: 'lg', skin: 'purple', block: true }} onClick={() => window.location.reload()}>Reintentar</Button>
      <div style={{ height: 15 }} />
      <Button options={{ type: 'outline', size: 'lg', skin: 'purple', block: true }} onClick={() => window.location.reload()}>Contactarme con soporte</Button>
    </Column>
  )
}


export default Component;