import { CardPayment } from '@mercadopago/sdk-react';
import React, { useEffect, useMemo, useState } from 'react';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { Title } from './styles'
import { User } from '../../../../types';
import { connect } from 'react-redux';

interface ComponentProps {
  onSuccess,
  onError,
  onSubmit,
  userInformation: User,
  price: number,
  publicKey: string,
  title: string,
  submitText: string
}

const Component = React.memo(({ userInformation, onSubmit, submitText, publicKey, price, title }: ComponentProps) => {
  const [sdkInititialized, setSdkInitialized] = useState<boolean>(false)

  const initialization = useMemo(() => {
    if (!!price && price > 0) {
      return {
        amount: price,
        payer: {
          email: process.env.NODE_ENV !== "production" ? "test_user_1799191595@testuser.com" : userInformation?.email,
        },
      }
    } else {
      return null
    }
  }, [price, userInformation?.email]);


  useEffect(() => {
    if (!sdkInititialized) {
      try {
        // initMercadoPago('APP_USR-8ac153fe-60bc-41e4-8bb5-cd6fd1c7cdc7');
        // initMercadoPago('APP_USR-71450594-f11e-404a-8897-21628283a695'); //Mp Vendedor Externo
        initMercadoPago(publicKey); //Mp Vendedor Externo
        setSdkInitialized(true)
        console.log("MercadoPago SDK initialized successfully");
      } catch (error) {
        setSdkInitialized(false)
        console.error("Failed to initialize MercadoPago SDK", error);
      }
    }
  }, [sdkInititialized])

  if (!initialization) {
    return <></>
  }

  return (
    <div style={{ width: '100%', maxWidth: 350 }}>
      <Title>{title}</Title>
      <div style={{ height: 20 }} />
      <CardPayment
        customization={{
          visual: {
            hideFormTitle: true,
            texts: {
              formSubmit: submitText
            },
            style: {
              theme: 'flat',
              customVariables: {},
            },
          },
          paymentMethods: {
            maxInstallments: 1,
          },
        }}
        initialization={initialization}
        onSubmit={onSubmit}
        onReady={() => console.log('El Brick está listo')}
        onError={(error) => console.log('Error al iniciar el Brick', error)}
      />
    </div>
  )
})


const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;

  return {
    userInformation,
  };
};

export default connect(state)(Component);