import Grid from "../../../components/Grid";
import Snackbar from "../../../components/Snackbar";
import { ghostLink } from "../../../helpers/ghost-link";
import { connect, useDispatch } from "react-redux";
import Tabs from "../../../components/Layout/ProfileLayout/components/Tabs";
import { showModal } from "./../../../store/actions/modal";
import ModalContactSupport from "./components/ModalContactSupport";
import {
  changeUserPassword,
  getSubscriptionsManagerUrl,
  getUserSubscriptions,
} from "./../../../store/actions/user";
import { useContext, useEffect, useMemo } from "react";
import { AuthContext } from "../../../providers/Auth";
import { FormTitle, Title, Data, Button, Section } from "./styles";
import ChangeEmail from "./components/ChangeEmail";
import ChangePassword from "./components/ChangePassword";
import ChangeUsername from "./components/ChangeUsername";
import ChangeAvatar from "./components/ChangeAvatar";
import ChangePhone from "./components/ChangePhone";
import Link from "../../../components/Link";
import { Layout, useLayout } from "../../../providers/LayoutProvider";

interface ComponentProps {
  setActiveTab?;
  userSubscriptions;
  userData;
  userPasswordStates;
  subscruptionsManagerUrl;
  userStates;
}

const Component = ({
  userSubscriptions,
  userData,
  userStates,
  userPasswordStates,
  subscruptionsManagerUrl,
  setActiveTab,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const { layout, setLayout } = useLayout();
  const { user } = useContext(AuthContext);

  const { data: subscruptionsManagerData, states: subscruptionsManagerStates } =
    subscruptionsManagerUrl;

  const { access_url } = subscruptionsManagerData || {};

  const isExpert = useMemo((): boolean => {
    return !!userData?.expert;
  }, [userData, userStates]);

  const goToSubscriptionsManager = () => {
    if (!!access_url) {
      ghostLink(access_url);
    } else {
      dispatch(getSubscriptionsManagerUrl());
    }
  };

  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "plain",
      footer: {
        visible: false,
      },
      header: {
        visible: true,
        actions: [],
      },
      page: {
        title: "Configuración de cuenta",
        description: "Configurar cuenta",
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      body: {
        visible: true,
      },
    }),
    [layout]
  );

  useEffect(() => {
    !!access_url && goToSubscriptionsManager();
  }, [subscruptionsManagerData]);

  useEffect(() => {
    !!user && dispatch(getUserSubscriptions(
      {
        filters: {
          status_in: "active,non_renewing,paid,pending_invite",
        }
      }
    ));
  }, [user]);

  useEffect(() => {
    setActiveTab("profile");
    setLayout(layoutData);
  }, []);

  useEffect(() => {
    if (subscruptionsManagerStates.error) {
      dispatch(showModal("modal-contact-support"));
    }
  }, [subscruptionsManagerStates]);

  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col sm={4}>
          <Title>Información general</Title>
          {isExpert && <ChangeAvatar />}
          {isExpert && <ChangeUsername />}
          <ChangeEmail />
          <ChangePassword />
          <ChangePhone />
        </Grid.Col>

        {/* <Grid.Col sm={4}>
          <Title>Administrar suscripciones</Title>
          {userSubscriptions?.length > 0 ? (
            <Button
              type="button"
              onClick={() => goToSubscriptionsManager()}
              loading={subscruptionsManagerStates.loading}
              options={{
                skin: "purple",
                size: "lg",
                type: "link",
              }}
            >
              Modificar
            </Button>
          ) : (
            "Aun no cuentas con suscripciones."
          )}
        </Grid.Col> */}

        {isExpert && (
          <Grid.Col sm={4}>
            <Title>Datos de cobro</Title>
            <Section>
              {userData?.expert?.currency === "ARS" ? (
                <Link
                  target="_blank"
                  options={{
                    skin: "purple",
                    size: "lg",
                    type: "link",
                    native: false,
                  }}
                  href="https://docs.google.com/forms/d/e/1FAIpQLSelZBVtlMsyYyeqtb8vpK3IPuwCrD9L7-418XUtQGoiA_mSug/viewform?usp=sf_link"
                >
                  Completar mis datos de cobro
                </Link>
              ) : (
                <Link
                  target="_blank"
                  options={{
                    skin: "purple",
                    size: "lg",
                    type: "link",
                    native: false,
                  }}
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfL1xhlZJyQg6ZxOWF_3x_P6qbETVLOvB0x05Bjoz9CTK1_Mg/viewform?usp=sf_link"
                >
                  Completar mis datos de cobro
                </Link>
              )}
            </Section>
          </Grid.Col>
        )}
      </Grid.Row>

      <Snackbar
        visible={userPasswordStates.success || userPasswordStates.error}
        options={{
          type: userPasswordStates.success ? "success" : "error",
        }}
      >
        {/* {"auth/too-many-requests"} */}
        {userPasswordStates.success
          ? "Continua desde tu correo."
          : "Error al enviar email."}
      </Snackbar>

      <ModalContactSupport />
    </Grid.Container>
  );
};

const state = ({ userStore }) => {
  const {
    subscriptions,
    information,
    changeUserEmail,
    changeUserPassword,
    subscruptionsManagerUrl,
  } = userStore;

  const { data: userData, states: userStates } = information;
  const { data: userSubscriptions } = subscriptions;

  return {
    userSubscriptions,
    userData,
    userStates,
    userEmailStates: changeUserEmail.states,
    userPasswordStates: changeUserPassword.states,
    subscruptionsManagerUrl,
  };
};

export default connect(state)(Component);
