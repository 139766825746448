import { useTheme } from "styled-components";
import Grid from '../../../../Grid';
import firebase from "firebase/app";
import LogoutMenu from "./LogoutMenu";
import LoginMenu from "./LoginMenu";
import { Header, Img, Navbar } from "./styles";

import { connect } from "react-redux";
import { useWindowSize } from "../../../../../hooks/useWindowSize";

const Component = (props: any) => {
  const theme = useTheme() as { logo: { small: string, big: string } };
  const { forwardedRef, currentPage } = props;
  var user = firebase.auth().currentUser;
  const { isMobile } = useWindowSize();

  return (
    <Header ref={forwardedRef}>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col xs={3} style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }} >
            <div>
              <a href="/" style={{ flexShrink: 0, width: 'auto', display: 'inline-flex', verticalAlign: 'middle' }}>
                {!isMobile && (
                  <Img
                    src={theme?.logo?.big}
                    width="128px"
                    height="auto"
                    className="logo-desktop"
                    alt="Klouser"
                    lazy
                  />
                )}
                {isMobile && (
                  <Img
                    src={theme?.logo?.small}
                    width="40px"
                    height="auto"
                    className="logo-mobile"
                    alt="Klouser"
                    lazy
                  />
                )}
              </a>
            </div>
          </Grid.Col>
          <Grid.Col xs={9}>
            <Navbar>
              {!!user ? <LoginMenu /> : <LogoutMenu currentPage={currentPage} />}
            </Navbar>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Header>
  );
};

const state = ({ pageStore }) => ({
  currentPage: pageStore.name,
});

export default connect(state)(Component);
